<template>
  <div v-if="event && event.items" class="container px-4">
    <div class="is-size-5 is-unselectable has-text-weight-semibold mb-2">
    Items
    </div>
    <div v-if="event.items.length === 0" class="is-size-7 has-text-grey-light mt-5 has-text-centered">
      No items yet, click "+ Add item" to add your first!
      <i class="far fa-arrow-alt-circle-up ml-1"></i>
    </div>
    <div v-for="(item, index) in event.items" :key="index">
      <nav
      @click="expandItemId(item.itemId)"
      class="level py-1 px-2 is-clickable is-unselectable is-mobile item-row-modifier mb-1"
      >
        <div class="level-left">
          <div class="level-item is-size-7">
            {{ item.title }}
          </div>
        </div>

        <div class="level-right">
          <p class="level-item"><strong>{{item.currency ? `${item.currency} ` : $store.state.baseCurrencySymbol}}{{ item.totalPaid }}</strong></p>
          <div class="level-item">
            <i v-if="item.itemId !== expandedItemId" class="fas fa-chevron-right fa-fw"></i>
            <i v-if="item.itemId === expandedItemId" class="fas fa-chevron-down fa-fw"></i>
          </div>
        </div>
      </nav>
      <div class="columns mx-1 pb-1 is-mobile mb-1 mt-1" v-if="item.itemId === expandedItemId">
        <div class="column p-0">
          <div v-if="event.itemOutputs[index].itemDiscount" class="container is-size-7 mb-1">
            Discounted by: -{{ `${$store.state.baseCurrencySymbol}${event.itemOutputs[index].itemDiscount}` }}
          </div>
          <div class="container is-size-7 mb-1">
            Paid by: {{ paidByTexts[index] }}
          </div>
          <div class="container is-size-7 mb-1">
            Split between: {{ splitBetweenTexts[index] }}
          </div>
          <div v-if="extraChargesTexts[index]" class="container is-size-7">
            Extra charges: {{ extraChargesTexts[index] }}
          </div>
          <div v-if="item.photo" @click="seeReceipt(item.photo)" class="button is-small is-outlined is-clickable mb-1 mr-1 px-2">
            <i class="fas fa-external-link-alt mr-1"></i>
            See receipt
          </div>
        </div>
        <div class="column is-narrow p-0 ml-1">
          <div v-if="canEdit" @click="toggleDeleteBillScreen(item.itemId)" class="button is-small is-danger is-outlined is-clickable mb-1 mr-1 px-2">
            <i class="far fa-trash-alt"></i>
          </div>
          <div v-if="canEdit" @click="editBill(item.itemId)" class="button is-small is-info is-outlined is-clickable mb-1  px-2">
            <i class="fas fa-pencil-alt"></i>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-if="showDeleteBillScreen === item.itemId"
        v-bind:class="{'is-active': showDeleteBillScreen}"
        >
          <div @click="toggleDeleteBillScreen(false)" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <DeleteBill
            :item="item"
            v-on:toggleDeleteBillScreen="toggleDeleteBillScreen()"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DeleteBill from '@/components/DeleteBill'

export default {
  name: 'Bills',
  components: {
    DeleteBill
  },
  data() {
    return {
      expandedItemId: null,
      showDeleteBillScreen: false
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    },
    paidByTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemPayments.map(itemPayment => {
          return `${this.shortenName(itemPayment.name)} (${this.$store.state.baseCurrencySymbol}${itemPayment.amount})`
        }).join(', ')
      })
    },
    splitBetweenTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemDebts.map(itemDebt => {
          return `${this.shortenName(itemDebt.name)} (${this.$store.state.baseCurrencySymbol}${itemDebt.amount})`
        }).join(', ')
      })
    },
    extraChargesTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemServiceCharges.map(itemServiceCharge => {
          return `${this.shortenName(itemServiceCharge.name)} (${this.$store.state.baseCurrencySymbol}${itemServiceCharge.amount.toFixed(2)})`
        }).join(', ')
      })
    },
    hasSettledPayments () {
      return this.event.payments.some(payment => {
        return payment.isSettled
      })
    }
  },
  methods: {
    seeReceipt(imageDataUrl) {
      var image = new Image()
      image.src = imageDataUrl
      var w = window.open('')
      w.document.write(image.outerHTML)
    },
    editBill(itemId) {
      if (this.hasSettledPayments) {
        const confirmed = confirm(`Editing an item will set all settlements as not-settled as values will change. Confirm?`)
        if (!confirmed) { return }
      }
      if(this.canEdit) {
        this.$emit('editBill', itemId)
      }
    },
    expandItemId(itemId){
      if(itemId !== this.expandedItemId) {
        this.expandedItemId = itemId
      } else {
        this.expandedItemId = null
      }
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    },
    toggleDeleteBillScreen(itemId) {
      if (this.hasSettledPayments) {
        const confirmed = confirm(`Deleting an item will set all settlements as not-settled as values will change. Confirm?`)
        if (!confirmed) { return }
      }
      this.showDeleteBillScreen = itemId ? itemId : false
    }
  }
}
</script>

<style>
.item-row-modifier {
  border: 1.5px solid #3298dc;
  border-radius: 5px;
}
</style>