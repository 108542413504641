<template>
  <div class="level is-mobile" v-bind:class="{'mt-2': !payer}">
    <div class="level-left">
      <div v-if="payer" @click="toggleIsPayer()" class="level-item is-clickable mr-1">
        <i v-if="payer.isPayer" class="far fa-check-square"></i>
        <i v-if="!payer.isPayer" class="far fa-square"></i>
      </div>
      <div @click="toggleIsPayer()" v-if="payer && payer.name" class="payer-name-modifier is-clickable">
        {{ payerDisplayName }}
      </div>
      <div v-if="!payer" class="level-item">
        <input
        class="input name-input-modifier has-background-info-light"
        v-bind:class="{'is-danger': newName && newName.length > 0 && isDuplicateNewName}"
        type="text"
        placeholder="Add new name"
        v-model="newName"
        @input="$event.target.composing = false"
        >
      </div>
    </div>
    <div class="level-right">
      <div v-if="payer || (!payer && newName)" class="level-item mr-1">
        <i class="fas fa-dollar-sign"></i>
      </div>
      <div v-if="payer" class="level-item">
        <input
        class="input amount-input-modifier"
        v-bind:class="{'glow': payer.isPayer && (!localValue)}"
        type="number"
        placeholder="0"
        v-model="localValue"
        >
      </div>
      <div v-if="!payer" class="level-item mr-1">
        <input
        v-if="newName"
        class="input new-amount-input-modifier"
        v-bind:class="{'glow is-displayed': newName && newName.length > 0 && !newAmount}"
        type="number"
        placeholder=""
        v-model="newAmount"
        :disabled="!newName || newName.length === 0"
        ref="amountInput"
        >
      </div>
      <div v-if="!payer" class="level-item">
        <div
        v-if="newName && newAmount"
        @click="addNewPayer()"
        class="button is-info px-2"
        v-bind:class="{'glow': newAmount && newAmount.length > 0}"
        :disabled="!(newName && newName.length > 0 && newAmount && newAmount > 0) || isDuplicateNewName"
        >
        <i class="fas fa-plus"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payer',
  props: ['payer'],
  data() {
    return {
      amountInput: null,
      newName: null,
      newAmount: null
    }
  },
  computed: {
    isDuplicateNewName(){
      return this.$store.state.event.names.includes(this.newName)
    },
    localValue: {
      get () {
        return this.payer && this.payer.amount ? this.payer.amount : null
      },
      set (value) {
        if(value !== this.localValue){
          this.$emit('updatePayerAmount', Number(value))
        }
      }
    },
    payerDisplayName() {
      return this.payer.name.length < 12 ? this.payer.name : this.payer.name.substring(0, 12) + '...'
    }
  },
  watch: {
    localValue(newLocalValue) {
      if(!isNaN(newLocalValue) && Number(newLocalValue) !== 0) {
        this.$emit('updatePayerIsPayer', true)
      } else {
        this.$emit('updatePayerIsPayer', false)
      }
    },
    newName(newName) {
      if(newName) {
        this.$emit('updateIsAdding', true)
      } else if(!newName || newName.length === 0){
        this.newAmount = null
      }
    },
    newAmount(newAmount) {
      if(newAmount) {
        this.$emit('updateIsAdding', true)
      } else {
        this.$emit('updateIsAdding', false)
      }
    }
  },
  methods: {
    toggleIsPayer() {
      if(this.payer.isPayer){
        this.localValue = null
      }
      this.$emit('updatePayerIsPayer', !this.payer.isPayer)
    },
    addNewPayer() {
      if(
        this.newName &&
        this.newName.length > 0 &&
        this.newAmount &&
        this.newAmount > 0 &&
        !this.isDuplicateNewName
      ){
        this.$emit('addNewPayer', {
          newName: this.newName,
          newAmount: Number(this.newAmount)
        })
        this.newName = null
        this.newAmount = null
      }
    }
  }
}
</script>

<style>
.payer-name-modifier {
 width: 40px;
}

.name-input-modifier {
  min-width: 160px !important;
}

.amount-input-modifier {
  max-width: 116px;
}

.new-amount-input-modifier {
  max-width: 80px
}
</style>