<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered mb-2">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color">
        Misc. Charges & Discounts
        </h3>
      </div>
      <div class="has-text-grey mb-3 is-size-7">
        Any misc. charges to be split across everyone, either equally or weighted by their expenses in the whole event. This is useful if each item is a line item of a receipt instead of a whole receipt.
      </div>
      <label class="label mb-0">Who paid for the misc. charges?</label>
      <div class="is-size-7 has-text-grey-light mt-0 mb-2">Delivery, Service Charges, Taxes...</div>
      <div class="container">
        <div class="columns bill-row-column is-desktop mb-3">
          <div v-for="(payer, index) in payers" :key="index" class="column py-1">
            <ServiceChargePayer
            :payer="payer"
            v-on:updatePayerAmount="updatePayerAmount(payer.name, $event)"
            v-on:updatePayerIsPayer="updatePayerIsPayer(payer.name, $event)"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <label class="label mb-2">Split method</label>
        <div class="tags has-addons">
          <span @click="setType('equal')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': type === 'equal'}">
            <div class="is-size-7">Equally</div>
          </span>
          <span @click="setType('proportion')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': type === 'proportion'}">
            <div class="is-size-7">Weighted</div>
          </span>
        </div>
      </div>
      <div class="container mt-5 pt-2 mb-5">
        <label class="label mb-0">Any discounts?</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-2">Vouchers, Coupons, Gifts...</div>
        <nav class="level is-mobile">
          <div class="level-left">
            <div class="level-item mr-1">
              <i class="fas fa-dollar-sign"></i>
            </div>
            <div class="level-item">
              <input
              class="input"
              type="number"
              placeholder=0
              v-model="reduction"
              >
            </div>
          </div>
        </nav>
      </div>
      <div class="container mt-5 pt-2 mb-5">
        <label class="label mb-0">Exclude certain items</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-2">Any items that are not affected by the misc. charges and discounts. By default, all items are included.</div>
        <div class="tags">
          <span
          v-for="(item, index) in event.items"
          :key="index"
          @click="addRemoveExcludedItemIds(item.itemId)"
          class="tag is-clickable is-large px-4 mr-1 mb-1"
          v-bind:class="{'is-info': excludedItemIds.includes(item.itemId)}">
            <div class="is-size-7">{{ item.title }}</div>
          </span>
        </div>
      </div>
      <nav class="level is-mobile mb-0">
        <div class="level-left">
          <div class="level-item">
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="is-size-6 has-text-weight-semibold">
              Total: ${{ totalChargesWithReduction }}
            </div>
          </div>
        </div>
      </nav>
      <div v-if="hasNegativeValue || (this.reduction && this.reduction < 0)" class="notification is-danger is-light my-1 py-3 is-size-7">
        Value needs to be above $0
      </div>

      <div v-if="hasMoreThan2Dp" class="notification is-danger is-light my-1 py-3 is-size-7">
        Maximum of 2 decimal places
      </div>
      <div
      @click="editOverallSpecialCharge()"
      v-bind:class="{'is-loading': loading}"
      class="button is-info is-fullwidth mt-2 mb-3"
      :disabled="totalCharges < 0 || hasNegativeValue || hasMoreThan2Dp || loading || (reduction !== null && reduction < 0)"
      >
      Set
      </div>
      <div
      v-if="!loading"
      class="container mb-6 has-text-centered has-text-grey"
      >
        <div @click="toggleOverallSpecialChargeScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'
import ServiceChargePayer from '@/components/ServiceChargePayer'

export default {
  name: 'OverallSpecialCharge',
  components: {
    ServiceChargePayer
  },
  data() {
    return {
      payers: [],
      type: 'equal',
      reduction: null,
      excludedItemIds: [],
      loading: false
    }
  },
  methods: {
    setType(value) {
      this.type = value
    },
    updatePayerAmount(name, value){
      this.payers.forEach(payer => {
        if(payer.name === name) {
          payer.amount = value
        }
      })
    },
    updatePayerIsPayer(name, value){
      this.payers.forEach(payer => {
        if(payer.name === name) {
          payer.isPayer = value
        }
      })
    },
    addRemoveExcludedItemIds(itemId){
      if(this.excludedItemIds.includes(itemId)) {
        this.excludedItemIds = this.excludedItemIds.filter(excludedItemId => {
          return excludedItemId !== itemId
        })
      } else {
        this.excludedItemIds.push(itemId)
      }
    },
    editOverallSpecialCharge(){
      if(!this.hasNegativeValue && !this.hasMoreThan2Dp && !this.loading && this.canEdit && !(this.reduction && this.reduction < 0)) {
        this.loading = true

        let overallServiceCharge = {
          reduction: null,
          payers: [],
          type: this.type,
          excludedItemIds: this.excludedItemIds
        }
        if(this.reduction && this.reduction > 0){
          overallServiceCharge.reduction = this.reduction
        }
        if(this.totalCharges > 0){
          overallServiceCharge.payers = this.payers
          overallServiceCharge.type = this.type
        }

        masterFunction({
          'methodName': 'edit-overall-service-charge',
          'eventId': this.event.eventId,
          'adminKey': this.$store.state.adminKey,
          overallServiceCharge
        }).then(() => {
          this.loading = false
          this.toggleOverallSpecialChargeScreen()
        }).catch(err => {
          console.log(err)
        })
      }
    },
    toggleOverallSpecialChargeScreen() {
      this.$emit('toggleOverallSpecialChargeScreen')
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    },
    totalCharges() {
      return this.payers.reduce((acc, payer) => {
        return acc + payer.amount
      }, 0)
    },
    totalChargesWithReduction() {
      return this.totalCharges - this.reduction
    },
    hasNegativeValue() {
      return (
        this.payers.length > 0 &&
        this.payers.some(payer => {
          return payer.amount && payer.amount < 0
        })
      )
    },
    hasMoreThan2Dp() {
      return (
        this.payers.length > 0 &&
        this.payers.some(payer => {
          return payer.amount && payer.amount.countDecimals() > 2
        })
      )
    }
  },
  mounted() {
    this.event.names.forEach(name => {
      this.payers.push({
        isPayer: false,
        name,
        amount: null
      })
    })
    
    if(this.event.overallServiceCharge){
      this.type = this.event.overallServiceCharge.type
      this.payers.forEach(payer => {
        this.event.overallServiceCharge.payers.forEach(overallServiceChargePayer => {
          if(payer.name === overallServiceChargePayer.name) {
            payer.isPayer = overallServiceChargePayer.isPayer
            payer.amount = overallServiceChargePayer.amount
          }
        })
      })
      this.reduction = this.event.overallServiceCharge.reduction
      if(this.event.overallServiceCharge.excludedItemIds) {
        this.excludedItemIds = this.event.overallServiceCharge.excludedItemIds
      }
    }
  }
}
</script>

<style>

</style>