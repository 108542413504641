<template>
  <div class="level is-mobile">
    <div class="level-left">
      <div v-if="specialCharge" @click="deleteSpecialCharge()" class="level-item pointer">
        <i class="far fa-check-square"></i>
      </div>
      <div v-if="specialCharge && specialCharge.name" class="level-item">
        {{ specialChargeName }}
      </div>
    </div>
    <div class="level-right">
      <div v-if="specialCharge" class="tags has-addons level-item">
        <span @click="setSpecialChargeSplitType('equal')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': specialCharge.split === 'equal'}">
          <i class="fas fa-equals"></i>
        </span>
        <span @click="setSpecialChargeSplitType('proportion')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': specialCharge.split === 'proportion'}">
          <i class="fas fa-weight"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitSpecialCharge',
  props: ['specialCharge', 'specialCharges'],
  data() {
    return {
      newName: null
    }
  },
  computed: {
    isDuplicateNewName(){
      return this.specialCharges.some(specialCharge => { specialCharge.name === this.newName })
    },
    localValue: {
      get () {
        return this.specialCharge && this.specialCharge.amount ? this.specialCharge.amount : null
      },
      set (value) {
        if(value !== this.localValue){
          this.$emit('updateSpecialChargeAmount', Number(value))
        }
      }
    },
    specialChargeName() {
      return this.specialCharge.name.length < 12 ? this.specialCharge.name : this.specialCharge.name.substring(0, 12) + '...'
    }
  },
  methods: {
    setSpecialChargeSplitType(specialChargeSplitType) {
      this.$emit('setSpecialChargeSplitType', specialChargeSplitType)
    },
    addNewSpecialCharge() {
      if(
        this.newName &&
        this.newName.length > 0 &&
        !this.isDuplicateNewName
      ){
        this.$emit('addNewSpecialCharge', { newName: this.newName })
        this.newName = null
      }
    },
    deleteSpecialCharge() {
      this.$emit('deleteSpecialCharge')
    },
    toastTooltip() {
      this.$toasted.show('... equally', {
        position: 'top-center',
        keepOnHover: true,
        duration: 8000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'equals'
      })
      this.$toasted.show('... weighted', {
        position: 'top-center',
        keepOnHover: true,
        duration: 8000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'weight-hanging'
      })
    }
  }
}
</script>

<style>
.name-input-modifier {
  max-width: 140px
}

.specialCharge-amount-modifier {
  max-width: 60px;
}
</style>