<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        View As
        </h3>
      </div>
      <div class="container mb-6">
        <label class="label mb-0">Select your name</label>
        <div class="is-size-7 has-text-grey-light mb-3">
          Not found? Add your name below.
        </div>
        <div class="buttons field">
          <button 
          @click="setName(name)"
          v-for="(name, index) in event.names"
          :key="index"
          class="button is-outlined is-info mr-1"
          :disabled="loading"
          >
            {{ shortenName(name) }}
          </button >
        </div>
      </div>
      <div class="container">
        <label class="label">Add new name</label>
        <div class="control">
          <input
          class="input"
          v-bind:class="{'is-loading': loading, 'is-danger': isDuplicateNewName}"
          type="text"
          placeholder="Add new name..."
          v-model="name"
          :disabled="loading"
          @input="$event.target.composing = false"
          >
          <div
            @click="addName()"
            v-bind:class="{'is-loading': loading}"
            class="button is-info is-fullwidth mt-3 mb-3"
            :disabled="name.trim().length === 0 || loading || isDuplicateNewName"
            >
            Add name
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'

export default {
  name: 'SetName',
  data() {
    return {
      loading: false,
      name: ''
    }
  },
  methods: {
    setName(name){
      if(!this.loading) {
        this.$store.commit('updateName', name)
        this.$emit('closeShowSetNameScreen')
      }
    },
    addName(){
      if(!this.loading && !this.isDuplicateNewName && this.name.trim().length > 0) {
        this.loading = true
        masterFunction({
          'methodName': 'add-name',
          'eventId': this.event.eventId,
          'name': this.name.trim(),
          'adminKey': this.$store.state.adminKey
        }).then(() => {
          this.$store.commit('updateName', this.name.trim())
          this.$emit('closeShowSetNameScreen')
          this.name = ''
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      }
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    isDuplicateNewName() {
      return (
        this.event &&
        !this.event.names.every(name => {
          return name !== this.name
        })
      )
    }
  }
}
</script>

<style>

</style>