<template>
  <div class="level is-mobile">
    <div class="level-left">
      <div @click="toggleIsPayer()" class="level-item is-clickable mr-1">
        <i v-if="payer.isPayer" class="far fa-check-square"></i>
        <i v-if="!payer.isPayer" class="far fa-square"></i>
      </div>
      <div @click="toggleIsPayer()" class="payer-name-modifier is-clickable">
        {{ payerDisplayName }}
      </div>
    </div>
    <div class="level-right">
      <div class="level-item mr-1">
        <i class="fas fa-dollar-sign"></i>
      </div>
      <div class="level-item">
        <input
        class="input amount-input-modifier"
        v-bind:class="{'glow': payer.isPayer && (!localValue)}"
        type="number"
        placeholder="0"
        v-model="localValue"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceChargePayer',
  props: ['payer'],
  data() {
    return {
      amountInput: null
    }
  },
  computed: {
    localValue: {
      get () {
        return this.payer && this.payer.amount ? this.payer.amount : null
      },
      set (value) {
        if(value !== this.localValue){
          this.$emit('updatePayerAmount', Number(value))
        }
      }
    },
    payerDisplayName() {
      return this.payer.name.length < 12 ? this.payer.name : this.payer.name.substring(0, 12) + '...'
    }
  },
  watch: {
    localValue(newLocalValue) {
      if(!isNaN(newLocalValue) && Number(newLocalValue) !== 0) {
        this.$emit('updatePayerIsPayer', true)
      } else {
        this.$emit('updatePayerIsPayer', false)
      }
    }
  },
  methods: {
    toggleIsPayer() {
      if(this.payer.isPayer){
        this.localValue = null
      }
      this.$emit('updatePayerIsPayer', !this.payer.isPayer)
    }
  }
}
</script>

<style>
.payer-name-modifier {
 width: 40px;
}

.name-input-modifier {
  min-width: 160px !important;
}

.amount-input-modifier {
  max-width: 116px;
}

.new-amount-input-modifier {
  max-width: 80px
}
</style>