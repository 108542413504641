<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        Settlement Calculation
        </h3>
      </div>
    <div class="container mb-4 is-size-7 has-text-grey">
      <div>Alex owe Brian $5 and Brian owe Charlie $5</div>
      <div> > then Alex pays $5 directly to Charlie</div>
      <br>
      <div>Alex owe Brian $5 and Brian owe Alex $2</div>
      <div> > then Alex pays $3 to Brian</div>
      <br>
    </div>
      <div
      v-if="!loading"
      class="container mb-6 has-text-centered has-text-grey"
      >
        <div @click="toggleSettlementScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettlementCalculation',
  data() {
    return {
      loading: false,
      deleteConfirmation: ''
    }
  },
  methods: {
    toggleSettlementScreen() {
      this.$emit('toggleSettlementScreen')
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    }
  }
}
</script>

<style>

</style>