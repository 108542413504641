<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color">
        Tutorial
        </h3>
        <div v-if="showType === null" class="has-text-grey mb-5">Select the type that fits you best</div>
      </div>
      <div v-if="showType === null" class="container has-text-centered">
        <img class="tutorial-img-modifier" src="../assets/smallbill.png">
        <div class="container mt-2">
          <button
            @click="showSecondPart('simple')"
            class="button is-small is-info is-outlined is-rounded has-monserrat-font"
            >
            I have 1 or more, not-so-complex bills to split
            <i class="fas fa-arrow-circle-right ml-2"></i>
          </button>
        </div>

        <img class="tutorial-img-modifier mt-6" src="../assets/bigbill.png">
        <div class="container mt-2">
          <button
            @click="showSecondPart('complex')"
            class="button is-small is-info is-outlined is-rounded has-monserrat-font"
            >
            I have 1 large, complex bill to split
            <i class="fas fa-arrow-circle-right ml-2"></i>
          </button>
        </div>
      </div>
      <div v-if="showType === 'complex'" class="container pt-5">
        <div class="mb-2">
          <div class="has-text-grey"><i class="far fa-hand-point-right mr-2"></i>Use "+ Add item", once for each item in the bill.</div>
          <div class="has-text-grey"><i class="far fa-hand-point-right mr-2"></i>Ignore the "Misc charge" screen in "+ Add item"</div>
          <div class="has-text-grey"><i class="far fa-hand-point-right mr-2"></i>Use the "Overall Misc. Charge" instead.</div>
        </div>
        <figure class="image">
          <img src="../assets/bigbilltut.png">
        </figure>
      </div>
      <div v-if="showType === 'simple'" class="container pt-5">
        <div class="mb-2">
          <div class="has-text-grey"><i class="far fa-hand-point-right mr-2"></i>Use "+ Add item", once for each bill.</div>
          <div class="has-text-grey"><i class="far fa-hand-point-right mr-2"></i>Add misc. charges accordingly for each bill in the "+ Add item" pop up.</div>
        </div>
        <figure class="image">
          <img src="../assets/smallbilltut.png">
        </figure>
      </div>
      <div
      class="container mt-6 mb-6 has-text-centered has-text-grey"
      >
        <div @click="toggleTutorialScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data() {
    return {
      showType: null
    }
  },
  methods: {
    toggleTutorialScreen() {
      this.showType = null
      this.$emit('toggleTutorialScreen')
    },
    showSecondPart(type) {
      this.showType = type
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    }
  }
}
</script>

<style>
.tutorial-img-modifier {
  max-width: 250px;
}
</style>