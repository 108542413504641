<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div v-if="!event" class="container pt-6 mt-6 has-text-centered">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-if="event" class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd mb-4 pb-6 mb-6">
      <nav class="level is-mobile mt-3 mb-1 px-4">
        <div v-if="!canEdit" class="level-item">
          <div class="has-text-grey-light">
            View-only <i class="fas fa-eye ml-1"></i>
          </div>
        </div>
        <div v-if="name && canEdit" class="level-left">
          <div class="level-item has-text-grey-light is-size-7">
            Viewing as
            <button
            @click="toggleShowSetNameScreen()"
            class="button is-rounded is-small is-clickable is-info is-outlined ml-2">
              {{ shortenName(name) }} <i class="fas fa-caret-down ml-2"></i>
            </button>
          </div>
        </div>
        <div class="level-right" v-if="canEdit">
          <div class="level-item">
            <button
            @click="addBill()"
            class="button is-small is-info is-rounded has-monserrat-font"
            >
            <i class="fas fa-plus mr-2"></i>
            Add item
            </button>
          </div>
        </div>
      </nav>
      <Bills class="mt-2 mb-5" v-on:editBill="editBill($event)"/>
      <OverallServiceCharges v-if="event.overallServiceCharge" class="mt-4 mb-5" v-on:toggleOverallSpecialChargeScreen="toggleOverallSpecialChargeScreen()"/>
      <Payments v-on:toggleSettlementScreen="toggleSettlementScreen()" class="mt-4 mb-5" />
      <Comments class="mt-4 mb-5"/>
      <div class="container px-4 mt-4 mb-5">
        <div class="is-size-5 is-unselectable has-text-weight-semibold mb-2">
        Tools
        </div>
        <div class="buttons">
          <div
          @click="toggleOverallSpecialChargeScreen()"
          class="box mb-1 mr-2 px-3 is-clickable has-text-grey tool-box-modifier"
          >
            <div class="container has-text-centered">
              <i class="fas fa-lg fa-file-invoice-dollar"></i>
            </div>
            <div class="mt-2 is-size-7 is-unselectable has-text-centered">
              Misc. Charges & Discounts
            </div>
          </div>
          <div
          @click="toggleCopyPasteScreen()"
          class="box mb-1 mr-2 px-3 is-clickable has-text-grey tool-box-modifier"
          >
            <div class="container has-text-centered">
              <i class="fas fa-lg fa-copy"></i>
            </div>
            <div class="container mt-2 is-size-7 is-unselectable">
              Copy All Data
            </div>
          </div>
          <div
          @click="togglePrivacyScreen()"
          class="box mb-1 mr-2 px-3 is-clickable has-text-grey tool-box-modifier"
          >
            <div class="container has-text-centered">
              <i class="fas fa-lg fa-user-lock"></i>
            </div>
            <div class="container mt-2 is-size-7 is-unselectable">
              Data & Privacy
            </div>
          </div>
          <div
          @click="toggleDownloadCsvScreen()"
          class="box mb-1 mr-2 px-3 is-clickable has-text-grey tool-box-modifier"
          >
            <div class="container has-text-centered">
              <i class="fas fa-lg fa-file-csv"></i>
            </div>
            <div class="container mt-2 is-size-7 is-unselectable">
              Download CSV
            </div>
          </div>
          <div
          @click="toggleTutorialScreen()"
          class="box mb-1 px-3 is-clickable has-text-grey tool-box-modifier"
          >
            <div class="container has-text-centered">
              <i class="fas fa-lg fa-book"></i>
            </div>
            <div class="container mt-2 is-size-7 is-unselectable">
              Tutorial
            </div>
          </div>
        </div>
      </div>
      <!-- <Promo class="pt-3" /> -->
      <div class="container has-text-centered mt-6 pt-6 px-4 mb-6 pb-6">
        <div class="is-size-7 has-text-grey-lighter">
          Made by M Afiq, out of his frustration of splitting bills.
        </div>
        <div
        class="is-size-7 mt-4 is-underlined is-unselectable is-clickable"
        >
        <a
        class="has-text-grey-lighter"
        href="https://www.linkedin.com/in/m-afiq/"
        target="_blank">
        <u>LinkedIn</u>
        </a> 
        </div>
      </div>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showSetNameScreen || forceShowSetNameScreen"
        v-bind:class="{'is-active': showSetNameScreen || forceShowSetNameScreen}"
        >
          <div @click="toggleShowSetNameScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <SetName
            v-on:toggleShowSetNameScreen="toggleShowSetNameScreen()"
            v-on:closeShowSetNameScreen="closeShowSetNameScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showAddBillScreen"
        v-bind:class="{'is-active': showAddBillScreen}"
        >
          <div @click="toggleShowAddBillScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <AddBill
            :showAddBillScreen="showAddBillScreen"
            :itemIdToEdit="itemIdToEdit"
            v-on:toggleShowAddBillScreen="toggleShowAddBillScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-if="showOverallSpecialChargeScreen"
        v-bind:class="{'is-active': showOverallSpecialChargeScreen}"
        >
          <div @click="toggleOverallSpecialChargeScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <OverallSpecialCharge
            v-on:toggleOverallSpecialChargeScreen="toggleOverallSpecialChargeScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showPrivacyScreen"
        v-bind:class="{'is-active': showPrivacyScreen}"
        >
          <div @click="togglePrivacyScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <Privacy
            v-on:togglePrivacyScreen="togglePrivacyScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showCopyPasteScreen"
        v-bind:class="{'is-active': showCopyPasteScreen}"
        >
          <div @click="toggleCopyPasteScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <CopyAll
            v-on:toggleCopyPasteScreen="toggleCopyPasteScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showTutorialScreen"
        v-bind:class="{'is-active': showTutorialScreen}"
        >
          <div @click="toggleTutorialScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <Tutorial
            v-on:toggleTutorialScreen="toggleTutorialScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showDownloadCsvScreen"
        v-bind:class="{'is-active': showDownloadCsvScreen}"
        >
          <div @click="toggleDownloadCsvScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <DownloadCsv
            v-on:toggleDownloadCsvScreen="toggleDownloadCsvScreen()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
        class="modal add-bill-screen-modifier"
        v-show="showSettlementScreen"
        v-bind:class="{'is-active': showSettlementScreen}"
        >
          <div @click="toggleSettlementScreen()" class="modal-background pointer"></div>
          <div class="modal-content add-bill-modal-modifier">
            <SettlementCalculation
            v-on:toggleSettlementScreen="toggleSettlementScreen()"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import AddBill from '@/components/AddBill'
import Bills from '@/components/Bills'
import OverallServiceCharges from '@/components/OverallServiceCharges'
import Payments from '@/components/Payments'
import Comments from '@/components/Comments'
import SetName from '@/components/SetName'
import Privacy from '@/components/Privacy'
import CopyAll from '@/components/CopyAll'
import OverallSpecialCharge from '@/components/OverallSpecialCharge'
import SettlementCalculation from '@/components/SettlementCalculation'
import Tutorial from '@/components/Tutorial'
import DownloadCsv from '@/components/DownloadCsv'
// import Promo from '@/components/Promo'

export default {
  name: 'Main',
  components: {
    AddBill,
    Bills,
    OverallServiceCharges,
    Payments,
    Comments,
    SetName,
    Privacy,
    CopyAll,
    OverallSpecialCharge,
    Tutorial,
    DownloadCsv,
    SettlementCalculation
    // Promo
  },
  data() {
    return {
      eventNameSlug: this.$route.params.nameSlug,
      eventId: this.$route.params.eventId,
      event: null,
      showOverallSpecialChargeScreen: false,
      showAddBillScreen: false,
      showSetNameScreen: false,
      showPrivacyScreen: false,
      showCopyPasteScreen: false,
      showTutorialScreen: false,
      showDownloadCsvScreen: false,
      showSettlementScreen: false,
      closedTutorialScreenBefore: false,
      itemIdToEdit: null,
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.canEdit
    },
    name() {
      return this.$store.state.name
    },
    forceShowSetNameScreen() {
      return !this.name && this.canEdit
    },
    hasSettledPayments () {
      return this.event.payments.some(payment => {
        return payment.isSettled
      })
    }
  },
  watch: {
    eventId: {
      immediate: true,
      handler(eventId) {
        if(eventId){
          const events = firebaseApp.firestore().collection('events')
          this.$bind('event', events.doc(eventId))
        }
      }
    },
    event(newEvent, oldEvent){
      if(JSON.stringify(newEvent) !== JSON.stringify(oldEvent)){
        this.$store.commit('updateEvent', newEvent)
        this.$store.commit('updateCanEdit')
      }
      if(newEvent) {
        this.updatePreviousEvents()
      }
      // disabled auto opening of tutorial at the start
      // if(
      //   !oldEvent &&
      //   newEvent &&
      //   newEvent.items.length === 0 &&
      //   !this.closedTutorialScreenBefore
      // ) {
      //   this.showTutorialScreen = true
      // }
    }
  },
  mounted() {
    if(!this.$route.params.eventId || !this.$route.params.nameSlug){
      this.$router.push({name: 'Start'})
    }
    if(this.$route.params.adminKey) {
      this.$store.commit('updateAdminKey', this.$route.params.adminKey)
    } else {
      this.$store.commit('updateName', null)
      this.$store.commit('updateAdminKey', null)
    }
  },
  methods: {
    toggleTutorialScreen() {
      this.showTutorialScreen = !this.showTutorialScreen
      if(!this.showTutorialScreen) {
        this.closedTutorialScreenBefore = true
      }
    },
    toggleOverallSpecialChargeScreen() {
      this.showOverallSpecialChargeScreen = !this.showOverallSpecialChargeScreen
    },
    toggleShowSetNameScreen() {
      this.showSetNameScreen = !this.showSetNameScreen
    },
    closeShowSetNameScreen() {
      this.showSetNameScreen = false
    },
    togglePrivacyScreen() {
      this.showPrivacyScreen = !this.showPrivacyScreen
    },
    toggleCopyPasteScreen() {
      this.showCopyPasteScreen = !this.showCopyPasteScreen
    },
    toggleDownloadCsvScreen() {
      this.showDownloadCsvScreen = !this.showDownloadCsvScreen
    },
    toggleShowAddBillScreen() {
      if(this.showAddBillScreen) {
        this.itemIdToEdit = null
      }
      this.showAddBillScreen = !this.showAddBillScreen
    },
    toggleSettlementScreen() {
      this.showSettlementScreen = !this.showSettlementScreen
    },
    addBill() {
      if (this.hasSettledPayments) {
        const confirmed = confirm(`Adding an item will set all settlements as not-settled as values will change. Confirm?`)
        if (!confirmed) { return }
      }
      this.itemIdToEdit = null
      this.toggleShowAddBillScreen()
    },
    editBill(itemId) {
      this.itemIdToEdit = itemId
      this.toggleShowAddBillScreen()
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    },
    updatePreviousEvents() {
      const previousEvents = localStorage.getItem('BestBillSplitterPreviousEventsString') ? JSON.parse(localStorage.getItem('BestBillSplitterPreviousEventsString')) : []
      if(
        previousEvents &&
        (
          previousEvents.length > 0 &&
          previousEvents.every(
            previousEvent => previousEvent.eventId !== this.$route.params.eventId
          )
        ) ||
        previousEvents.length === 0
      ) {
        previousEvents.push({
          name: this.event.name,
          nameSlug: this.event.nameSlug,
          eventId: this.event.eventId,
          adminKey: this.$route.params.adminKey
        })
      } else {
        previousEvents.forEach(previousEvent => {
          if(previousEvent.eventId === this.event.eventId){
            previousEvent = {
              name: this.event.name,
              nameSlug: this.event.nameSlug,
              eventId: this.event.eventId,
              adminKey: this.$route.params.adminKey
            }
          }
        })
      }
      if(previousEvents.length > 5) {
        previousEvents.shift()
      }
      localStorage.setItem('BestBillSplitterPreviousEventsString', JSON.stringify(previousEvents))
    }
  }
}
</script>

<style>
.add-bill-modifier {
  height: 80vh;
}

.add-bill-button-modifier {
  position: fixed; 
  left: 50%;
  bottom: 2%;
  transform: translateX(-50%);
  width: 160px;
  max-width: 550px;
}

.add-bill-screen-modifier {
  height: 100vh;
  flex-direction: column-reverse !important;
  justify-content: end !important;
  z-index: 31;
}

.add-bill-modal-modifier {
  height: 90vh !important;
  max-height: none !important;
}

.tool-box-modifier {
  box-shadow: none;
  border: 1px solid #dbdbdb;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3298dc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>