<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        Copy All Data
        </h3>
      </div>
      <div class="container mb-2 is-size-7 has-text-grey">
        <article class="message">
        <textarea class="textarea is-size-7 has-background-grey-lighter" rows="10" v-model="textToCopy"></textarea>
        </article>
      </div>
      <div class="buttons">
        <button 
          @click="copy()"
          class="button is-fullwidth has-text-grey m-0"
          >
            {{ !copied ? 'copy to clipboard' : 'copied' }}
        </button >
      </div>
      <div
      class="container mb-6 has-text-centered has-text-grey"
      >
        <div @click="toggleCopyPasteScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyAll',
  data() {
    return {
      copied: false
    }
  },
  methods: {
    toggleCopyPasteScreen() {
      this.copied = false
      this.$emit('toggleCopyPasteScreen')
    },
    copy() {
      navigator.clipboard.writeText(this.textToCopy)
      .then(() => {
        this.copied = true
        setTimeout(this.setCopiedToFalse, 1000)
      })
      .catch(err => {
        console.log(err)
      })
    },
    setCopiedToFalse(){
      this.copied = false
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    textToCopy() {
      let textToCopy = ''
      const event = this.event
      textToCopy += `**${event.name}**\n`
      textToCopy += `\n`
      textToCopy += `-Settlements-\n`
      event.payments.forEach((payment) => {
        textToCopy += `${payment.from} to ${payment.to} | ${this.$store.state.baseCurrencySymbol}${payment.amount}`
        textToCopy += `\n`
      })
      textToCopy += `\n`
      textToCopy += `\n-Items-\n`
      event.items.forEach((item, index) => {
        textToCopy += `${index + 1}) ${item.title} (${item.currency ? `${item.currency} ` : this.$store.state.baseCurrencySymbol}${item.totalPaid})\n`
        textToCopy += `Paid by: ${this.paidByTexts[index]}`
        textToCopy += `\n`
        textToCopy += `Split between: ${this.splitBetweenTexts[index]}`
        textToCopy += `\n`
        if(this.extraChargesTexts[index] && this.extraChargesTexts[index].length > 0){
          textToCopy += `Extra charges: ${this.extraChargesTexts[index]}`
          textToCopy += `\n`
        }
        if(item.photo){
          textToCopy += `View photo of receipt via link below`
          textToCopy += `\n`
        }
        textToCopy += `\n`
      })
      if(event.overallServiceCharge) {
        textToCopy += `-Misc. Charges & Discounts-\n`
        if(event.overallServiceCharge.payers.length > 0) {
          textToCopy += `*${event.overallServiceCharge.type === 'equal' ? 'split equally' : 'weighted by expenses'}*\n`
          event.overallServiceCharge.payers.forEach((payer) => {
            if(payer.isPayer){
              textToCopy += `${payer.name} (${this.$store.state.baseCurrencySymbol}${payer.amount})`
              textToCopy += `\n`
            }
          })
        }
        if(event.overallServiceCharge.reduction) {
          textToCopy += `\n`
          textToCopy += `Discounts: ${this.$store.state.baseCurrencySymbol}${event.overallServiceCharge.reduction}`
          textToCopy += `\n`
        }
      }
      if(event.comments.length > 0) {
        textToCopy += `-Comments-\n`
        event.comments.forEach((comment) => {
          textToCopy += `${comment.from}: ${comment.comment}`
          textToCopy += `\n`
        })
      }
      textToCopy += `\n`
      textToCopy += `View in browser: https://bestbillsplitter.com/view/${event.nameSlug}/${event.eventId}`
      return textToCopy
    },
    paidByTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemPayments.map(itemPayment => {
          return `${itemPayment.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemPayment.amount})`
        }).join(', ')
      })
    },
    splitBetweenTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemDebts.map(itemDebt => {
          return `${itemDebt.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemDebt.amount})`
        }).join(', ')
      })
    },
    extraChargesTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemServiceCharges.map(itemServiceCharge => {
          return `${itemServiceCharge.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemServiceCharge.amount.toFixed(2)})`
        }).join(', ')
      })
    }
  }
}
</script>

<style>

</style>