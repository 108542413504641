<template>
  <div v-if="event && event.payments" class="container px-4">
    <div class="is-size-5 is-unselectable has-text-weight-semibold">
    Settlements
    </div>
    <p @click="toggleSettlementScreen()" class="help has-text-grey-light mt-0 mb-2 is-size-7 is-clickable">Looks weird? Click to learn more<i class="fas fa-question-circle ml-1"></i></p>
    <div v-if="event.payments.length === 0" class="is-size-7 has-text-grey-light mt-5 has-text-centered">
      No settlements needed for now.
    </div>
    <div>
      <nav v-for="(payment, index) in event.payments" :key="index" class="level py-1 px-2 is-unselectable is-mobile payment-row-modifier mb-1">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item is-size-7">
            {{ shortenName(payment.from) }} gives {{$store.state.baseCurrencySymbol}}{{ payment.amount }} to {{ shortenName(payment.to) }}
          </div>
        </div>

        <div class="level-right">
          <div class="level-item is-size-7 mr-0 has-text-grey-light">
            settled?
          </div>
          <div
          @click="setSettlement(payment.from, payment.to, payment.isSettled)"
          class="level-item"
          v-bind:class="{'is-clickable': canEdit}"
          >
            <i v-if="!loading &&!payment.isSettled" class="far fa-square fa-fw" v-bind:class="{'has-text-info': canEdit}"></i>
            <i v-if="!loading &&payment.isSettled" class="fas fa-check-square fa-fw" v-bind:class="{'has-text-info': canEdit}"></i>
            <i v-if="loading" class="spinning fas fa-spinner fa-fw"></i>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'

export default {
  name: 'Payments',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    }
  },
  methods: {
    setSettlement(nameFrom, nameTo, isSettled) {
      if(!this.loading && this.canEdit) {
        this.loading = true
        masterFunction({
          'methodName': 'set-settlement',
          'eventId': this.event.eventId,
          nameFrom,
          nameTo,
          'isSettled': !isSettled,
          'adminKey': this.$store.state.adminKey
        }).then(() => {
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      } else if(!this.canEdit) {
        this.$toasted.show('Cannot set/unset settlements in view-only mode.', {
          position: 'top-center',
          keepOnHover: true,
          duration: 2000,
          className: 'toast-modifier'
        })
      }
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    },
    toggleSettlementScreen() {
      this.$emit('toggleSettlementScreen')
    }
  }
}
</script>

<style>
.payment-row-modifier {
  height: 35px;
  border: 1.5px solid;
  border-radius: 5px;
}
</style>