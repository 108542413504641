<template>
  <div class="level is-mobile">
    <div @click="toggleIsSpecialCharge()" class="level-left">
      <div v-if="specialCharge" class="level-item pointer">
        <i v-if="!specialCharge.isSpecialCharge" class="far fa-square"></i>
        <i v-if="specialCharge.isSpecialCharge" class="far fa-check-square"></i>
      </div>
      <div v-if="specialCharge && specialCharge.name" class="level-item is-clickable is-unselectable">
        {{ specialChargeName  }}
      </div>
      <div v-if="!specialCharge" class="level-item mr-1">
        <input
        class="input name-input-modifier has-background-info-light"
        v-bind:class="{
          'is-danger': newName && newName.length > 0 && isDuplicateNewName
        }"
        type="text"
        placeholder="Add new"
        v-model="newName"
        @input="$event.target.composing = false"
        >
      </div>
      <div v-if="!specialCharge" class="level-item">
        <div
        v-if="newName"
        @click="addNewSpecialCharge()"
        class="button is-info"
        v-bind:class="{'glow': newName}"
        :disabled="!(newName && newName.length > 0) || isDuplicateNewName"
        >
        <i class="fas fa-plus">
        </i>
        </div>
      </div>
    </div>
    <div class="level-right">
      <div v-if="specialCharge" class="tags has-addons level-item">
        <span @click="setSpecialChargeType('absolute')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': specialCharge.type === 'absolute'}">
          <i class="fas fa-dollar-sign"></i>
        </span>
        <span @click="setSpecialChargeType('percentage')" class="tag is-clickable is-large px-2 mb-0" v-bind:class="{'is-info': specialCharge.type === 'percentage'}">
          <i class="fas fa-percentage"></i>
        </span>
      </div>
      <div v-if="specialCharge" class="level-item mr-0">
        <input
        class="input specialCharge-amount-modifier"
        v-bind:class="{'glow': specialCharge.isSpecialCharge && (!localValue || localValue < 0)}"
        type="number"
        placeholder="0"
        v-model="localValue"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialCharge',
  props: ['specialCharge', 'specialCharges'],
  data() {
    return {
      newName: null
    }
  },
  computed: {
    isDuplicateNewName(){
      return this.specialCharges.some(specialCharge => { specialCharge.name === this.newName })
    },
    localValue: {
      get () {
        return this.specialCharge && this.specialCharge.amount ? this.specialCharge.amount : null
      },
      set (value) {
        if(value !== this.localValue){
          this.$emit('updateSpecialChargeAmount', Number(value))
        }
      }
    },
    specialChargeName() {
      return this.specialCharge.name.length < 16 ? this.specialCharge.name : this.specialCharge.name.substring(0, 15) + '...'
    }
  },
  watch: {
    newName(newName) {
      if(newName) {
        this.$emit('updateIsAdding', true)
      } else {
        this.$emit('updateIsAdding', false)
      }
    }
  },
  methods: {
    setSpecialChargeType(specialChargeType) {
      this.$emit('setSpecialChargeType', specialChargeType)
    },
    addNewSpecialCharge() {
      if(
        this.newName &&
        this.newName.length > 0 &&
        !this.isDuplicateNewName
      ){
        this.$emit('addNewSpecialCharge', { newName: this.newName })
        this.newName = null
      }
    },
    deleteSpecialCharge() {
      this.$emit('deleteSpecialCharge')
    },
    toggleIsSpecialCharge() {
      this.$emit('toggleIsSpecialCharge', !this.specialCharge.isSpecialCharge)
    }
  }
}
</script>

<style>
.name-input-modifier {
  max-width: 160px
}

.specialCharge-amount-modifier {
  max-width: 60px;
}
</style>