<template>
  <div class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <!-- <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        {{
          billName && stage > 0 ? billName : itemIdToEdit ? 'Edit Item' : 'Add Item'
        }}
        </h3>
      </div> -->

      <nav class="columns mb-0 is-mobile" v-show="stage !== 5">
        <div class="column mb-0 pb-0">
          <div
          @click="prevStage()"
          class="is-clickable is-size-7 is-unselectable has-text-grey-light"
          v-bind:class="{'is-hidden': loading}"
          >
          <i class="fas fa-chevron-left"></i>
            back
          </div>
        </div>
        <div class="column mb-0 pb-0">
          <div class="has-text-grey-light is-size-7 is-pulled-right">
            {{ stage + 1 }} of 5 steps
          </div>
        </div>
      </nav>

      <div class="container has-text-centered mb-4">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-1">
        {{
          billName && stage > 0 ? billName : itemIdToEdit ? 'Edit item' : 'Add item'
        }}
        </h3>
        <span
        @click="stage = 0.5"
        v-if="$store.state.baseCurrencySymbol !== '$' && stage !== 0.5"
        class="tag is-info is-light is-small is-clickable is-unselectable">
        {{ addBillCurrencySymbol }}
        </span>
      </div>

      <div v-if="stage === 0" class="field">
        <label class="label">Item name</label>
        <div class="control mb-2">
          <input
          class="input"
          type="text"
          placeholder="Cab fare, Dinner at Jone's, Snacks..."
          v-model="billName"
          @input="$event.target.composing = false"
          >
        </div>
        <div class="is-size-7 has-text-grey-light">
          Shortcuts
        </div>
        <div class="buttons field mb-3">
          <button 
          @click="billName = name"
          v-for="(name, index) in itemTemplateNames"
          :key="index"
          class="button is-small is-outlined is-rounded mr-1"
          :disabled="loading"
          >
            {{ name }}
          </button >
        </div>
        <input
        type="file"
        style="display: none"
        ref="fileInput"
        accept="image/*"
        @change="filePicked"
        >
        <div v-if="photo" class="container mb-2">
          <div class="box" v-bind:class="{'is-hidden': !photo}">
            <img class="files-image" :src="photo">
          </div>
        </div>
        <div class="buttons mb-3">
          <div
          @click="pickFile"
          class="button is-info is-outlined mt-1"
          >
            <i class="fas fa-camera mr-2"></i>
            {{ photo ? 'Change receipt photo' : 'Upload receipt photo' }}
          </div>
          <div
          v-if="photo"
          @click="removePhoto"
          class="button is-danger is-outlined mt-1"
          >
            <i class="fas fa-trash-alt"></i>
          </div>
        </div>
      </div>
      
      <div v-if="stage === 0.5" class="field">
        <label class="label mb-2">Currency settings</label>
        <div class="container my-1">
          <div class="is-size-7 has-font-weight-semibold">
            What's the item's currency code?
          </div>
          <div class="control">
            <input
            class="input"
            type="text"
            placeholder="SGD, USD, EUR..."
            :value="fromCurrency ? fromCurrency.toUpperCase() : ''"
            @input="fromCurrency = $event.target.value.toUpperCase()"
            >
          </div>
        </div>
        <div class="container my-1 mt-3">
          <div class="is-size-7 has-font-weight-semibold">
            What's the base currency code?
          </div>
          <div class="control">
            <input
            class="input"
            type="text"
            placeholder="SGD, USD, EUR..."
            :value="baseCurrency ? baseCurrency.toUpperCase(): ''"
            @input="baseCurrency = $event.target.value.toUpperCase()"
            :readonly="event.baseCurrency"
            v-bind:class="{'has-background-grey-lighter': event.baseCurrency}"
            >
            <p class="help has-text-grey-light mt-0 mb-2 is-size-7 is-unselectable">The currency that all items will be converted to. Cannot be changed once set.</p>
          </div>
        </div>
        <div v-if="currencyValidityType === 'VALID'" class="container my-1 mt-3">
          <div class="is-size-7 has-font-weight-semibold">
            The conversion from {{ fromCurrency }} to {{ baseCurrency }} is...
          </div>
          <div class="control">
            <input
            class="input"
            type="number"
            v-model="rate"
            @input="$event.target.composing = false"
            >
            <p class="help has-text-grey-light mt-0 mb-2 is-size-7 is-unselectable">Data from fixer.io. Can be edited if needed.</p>
          </div>
        </div>
      </div>
      <div v-if="currencyValidityType === 'NEED_3_CHARS'" class="notification is-danger is-light my-1 py-3 is-size-7">
        Currency codes need to be 3 characters, such as "USD"
      </div>
      <div v-if="currencyValidityType === 'WRONG_CODE'" class="notification is-danger is-light my-1 py-3 is-size-7">
        Invalid currency code(s)
      </div>

      <div v-if="stage === 1" class="field">
        <label class="label mb-0">Who <u>first</u> paid for this item?</label>
        <div @click="stage = 0.5" class="is-size-7 has-text-info mt-0 mb-5 is-unselectable is-clickable">Paid in a different currency? <u>Click here.</u></div>
        <div class="columns bill-row-column is-desktop mb-5">
          <div v-for="(payer, index) in payers" :key="index" class="column py-1">
            <Payer
            :payer="payer"
            v-on:updatePayerAmount="updatePayerAmount(payer.name, $event)"
            v-on:updatePayerIsPayer="updatePayerIsPayer(payer.name, $event)"
            />
          </div>
          <div class="column py-1">
            <Payer
              :payer="null"
              v-on:addNewPayer="addNewPayer($event.newName, $event.newAmount)"
              v-on:updateIsAdding="updateIsAdding($event)"
            />
          </div>
        </div>
        <nav class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <div class="is-size-6 has-text-weight-semibold">
                Total amount paid: {{ addBillCurrencySymbol }}{{ totalPaid }}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div v-if="stage === 2" class="field">
        <label class="label mb-0">Any misc. charges?</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-5">Delivery, Service Charges, Taxes...</div>
        <div class="columns bill-row-column is-desktop mb-5">
          <div v-for="(specialCharge, index) in specialCharges" :key="index" class="column py-2">
            <SpecialCharge
            :specialCharge="specialCharge"
            :specialCharges="specialCharges"
            v-on:setSpecialChargeType="setSpecialChargeType(specialCharge.name, $event)"
            v-on:updateSpecialChargeAmount="updateSpecialChargeAmount(specialCharge.name, $event)"
            v-on:toggleIsSpecialCharge="toggleIsSpecialCharge(specialCharge.name, $event)"
            />
          </div>
          <div class="column pb-1 pt-2">
            <SpecialCharge
              :specialCharge="null"
              :specialCharges="specialCharges"
              v-on:addNewSpecialCharge="addNewSpecialCharge($event.newName)"
              v-on:updateIsAdding="updateIsAdding($event)"
            />
          </div>
          <!-- <div class="has-text-grey mt-3 px-3">Shortcuts:</div>
          <div class="buttons field px-3">
            <button 
            @click="addNewSpecialCharge(specialChargesTemplate)"
            v-for="(specialChargesTemplate, index) in specialChargesTemplates"
            :key="index"
            class="button is-small has-text-grey mr-1"
            >
              {{ specialChargesTemplate }}
            </button >
          </div> -->
        </div>
        <div v-if="totalSpecialCharges" class="is-size-7 has-text-grey">
          Charges that are by percentage are applied in sequence of appearance above.
          e.g. service charge is applied first before applying GST as a whole.
        </div>
        <div v-if="totalSpecialCharges" class="is-size-6 has-text-weight-semibold">
          Out of {{ addBillCurrencySymbol }}{{ totalPaid }}, {{ addBillCurrencySymbol }}{{ totalSpecialCharges }} are extra charges.
        </div>
      </div>

      <div v-if="stage === 3" class="field">
        <label class="label mb-0">Split misc. charges</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-1">Equally or weighted based on individual expenses</div>
        <nav class="level is-mobile mb-3">
          <div class="level-left"><div class="level-item"></div></div>
          <div class="level-right">
            <div class="level-item">
              <i @click="toastSplitExtraChargesTooltip()" class="far fa-question-circle has-text-grey fa-lg is-clickable mb-1"></i>
            </div>
          </div>
        </nav>
        <div class="columns bill-row-column is-desktop mb-5">
          <div v-for="(specialCharge, index) in specialCharges" :key="index" class="column py-2">
            <SplitSpecialCharge
            :specialCharge="specialCharge"
            :specialCharges="specialCharges"
            v-on:setSpecialChargeSplitType="setSpecialChargeSplitType(specialCharge.name, $event)"
            />
          </div>
        </div>
        <!-- <div v-if="totalSpecialCharges" class="is-size-7 has-text-grey">
          If split based on individual expenses: the person who spent $10 will pay 10X more than those who spent just $1.
        </div> -->
      </div>

      <div v-if="stage === 4" class="field">
        <label class="label mb-1">Who is part of this split?</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-2">
          Example: If a pizza was shared but Tom ordered dessert for him alone (in the same receipt), then the pizza will be in the Shared Portion, while the dessert will be in the Individual Portion.
        </div>
        <nav class="level is-mobile mb-2">
          <div class="level-left">
            <div class="level-item">
              <label class="label has-text-grey">Shared portion</label>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <i @click="toastPayeesTooltip()" class="far fa-question-circle has-text-grey fa-lg is-clickable mb-1"></i>
            </div>
          </div>
        </nav>
        <div class="columns bill-row-column is-desktop mb-5">
          <div v-for="(payee, index) in payees" :key="index" class="column py-2">
            <Payee
            :payees="payees"
            :payee="payee"
            v-on:setPayeeType="setPayeeType(payee.name, $event)"
            v-on:updatePayeeIsPayee="updatePayeeIsPayee(payee.name, $event)"
            v-on:updatePayeeAmount="updatePayeeAmount(payee.name, $event)"
            />
          </div>
          <div class="column pb-1 pt-3">
            <Payee
              :payees="payees"
              :payee="null"
              v-on:addNewPayee="addNewPayee($event.newName)"
              v-on:updateIsAdding="updateIsAdding($event)"
            />
          </div>
        </div>

        <label class="label has-text-grey mb-0">Individual portion</label>
        <div class="is-size-7 has-text-grey-light mt-0 mb-2">
          Example: If Tom and Susan ordered 2 desserts respectively ($5 and $7 for example), then enter $5 for Tom and $7 for Susan.
        </div>
        <div class="columns bill-row-column is-desktop mb-5">
          <div v-for="(payeeInd, index) in payeeInds" :key="index" class="column py-2">
            <PayeeIndividual
            :payeeInds="payeeInds"
            :payeeInd="payeeInd"
            v-on:updatePayeeIndIsPayee="updatePayeeIndIsPayee(payeeInd.name, $event)"
            v-on:updatePayeeIndAmount="updatePayeeIndAmount(payeeInd.name, $event)"
            />
          </div>
          <div class="column pb-1 pt-2">
            <PayeeIndividual
            :payeeInds="payeeInds"
            :payeeInd="null"
              v-on:addNewPayee="addNewPayee($event.newName)"
              v-on:updateIsAdding="updateIsAdding($event)"
            />
          </div>
        </div>
      </div>

      <div v-if="stage === 5" class="field has-text-centered">
        <div class="container my-5">
          <i class="far fa-10x fa-check-circle has-text-success tick-animation"></i>
        </div>
        <div class="mb-1 is-size-5 mt-5">Done!</div>
        <div class="has-text-grey mb-5">Made a mistake? Press the <i class="fas fa-pencil-alt"></i> icon on the main screen to make changes.</div>
      </div>

      <div v-if="exceedsFileSize" class="notification is-danger is-light my-1 py-3 is-size-7">
        Image size too big, use a different image
      </div>

      <div v-if="hasNegativeValue" class="notification is-danger is-light my-1 py-3 is-size-7">
        Value needs to be above $0
      </div>

      <div v-if="hasMoreThan2Dp" class="notification is-danger is-light my-1 py-3 is-size-7">
        Maximum of 2 decimal places
      </div>

      <div v-if="invalidContribution" class="notification is-danger is-light my-1 py-3 is-size-7">
        {{
          contributionNeeded > 0 ?
          `Contributions are not enough. Need ${addBillCurrencySymbol}${contributionNeeded} more.`:
          `Too much contributions. Need ${addBillCurrencySymbol}${contributionNeeded} less. If you have misc. charges and you are specifying them ($) , do not include them in your calculations.`
        }}
      </div>

      <div
      v-show="hideNextStageButton"
      @click="nextStage()"
      v-bind:class="{'is-loading': loading}"
      id="add-bill-action-button"
      class="button is-info is-fullwidth mt-3"
      :disabled="!allowNextStageButton || hasNegativeValue || hasMoreThan2Dp || invalidContribution || loading || isAdding || (currencyValidityType && (currencyValidityType !== 'VALID' || !this.rate))"
      >
      {{ buttonText[stage] }}
      </div>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'
import Payer from '@/components/Payer'
import SpecialCharge from '@/components/SpecialCharge'
import SplitSpecialCharge from '@/components/SplitSpecialCharge'
import Payee from '@/components/Payee'
import PayeeIndividual from '@/components/PayeeIndividual'
import currencies from '@/utils/currencies'
import imageCompression from 'browser-image-compression'


export default {
  name: 'AddBill',
  props: ['showAddBillScreen', 'itemIdToEdit'],
  components: {
    Payer,
    SpecialCharge,
    SplitSpecialCharge,
    Payee,
    PayeeIndividual
  },
  data() {
    return {
      stage: 0,
      loading: false,
      billName: '',
      payers: [],
      specialCharges: [],
      payees: [],
      payeeInds: [],
      isAdding: false,
      fromCurrency: '',
      baseCurrency: '',
      rate: null,
      confirmedFromCurrency: null,
      confirmedBaseCurrency: null,
      confirmedRate: null,
      currencyChanged: false,
      filename: null,
      photo: null,
      photoExt: null
    }
  },
  methods: {
    removePhoto () {
      this.photo = null
    },
    pickFile () {
      this.loading = false
      this.filename = null
      this.photo = null
      this.$refs.fileInput.value = null
      this.$refs.fileInput.click()
    },
    async filePicked (event) {
      const files = event.target.files
      const filename = files[0].name
      this.filename = filename
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.photo = fileReader.result
      })
      this.photo = files[0]
      this.photoExt = filename.slice(this.filename.lastIndexOf('.'))
      await this.compress()
      fileReader.readAsDataURL(this.photo)
    },
    async compress () {
      this.compressing = true
      this.photo = await imageCompression(this.photo, {
        maxSizeMB: 0.49
      })
      this.compressing = false
    },
    toastSplitExtraChargesTooltip() {
      this.$toasted.show('... equally', {
        position: 'top-center',
        keepOnHover: true,
        duration: 6000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'equals'
      })
      this.$toasted.show('... weighted', {
        position: 'top-center',
        keepOnHover: true,
        duration: 6000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'weight'
      })
    },
    toastPayeesTooltip() {
      this.$toasted.show('... equally divide remaining', {
        position: 'top-center',
        keepOnHover: true,
        duration: 6000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'equals'
      })
      this.$toasted.show('... indicate exact amount', {
        position: 'top-center',
        keepOnHover: true,
        duration: 6000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'dollar-sign'
      })
      this.$toasted.show('... percentage of total', {
        position: 'top-center',
        keepOnHover: true,
        duration: 6000,
        className: 'toast-modifier',
        iconPack: 'fontawesome',
        icon: 'percentage'
      })
    },
    updateIsAdding(value) {
      this.isAdding = value
    },
    resetAddBill() {
      this.stage = 0
      this.billName = '',
      this.payers = []
      this.specialCharges = []
      this.payees = []
      this.payeeInds = []
      this.isAdding = false
      this.fromCurrency = ''
      this.baseCurrency = ''
      this.rate = null
      this.confirmedFromCurrency = null
      this.confirmedBaseCurrency = null
      this.confirmedRate = null
      this.photo = null
      this.photoExt = null
    },
    addBill() {
      this.loading = true
      masterFunction({
        'methodName': 'edit-item',
        'eventId': this.event.eventId,
        'item': {
          itemId: this.itemIdToEdit,
          title: this.billName.trim(),
          payers: this.payers,
          payees: this.payees,
          payeeInds: this.payeeInds,
          specialCharges: this.specialCharges,
          currency: this.confirmedFromCurrency,
          photo: this.photo,
          photoExt: this.photoExt
        },
        'adminKey': this.$store.state.adminKey
      }).then(() => {
        this.stage = 5
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    setBaseAndAddRate() {
      if(this.currencyValidityType === 'VALID' && this.rate) {
        this.confirmedFromCurrency = this.fromCurrency
        this.confirmedBaseCurrency = this.baseCurrency
        this.confirmedRate = this.rate
        this.loading = true
        masterFunction({
          'methodName': 'set-base-currency-and-add-rate',
          'eventId': this.event.eventId,
          'baseCurrency': this.confirmedBaseCurrency,
          'fromCurrency': this.confirmedFromCurrency,
          'rate': this.confirmedRate,
          'adminKey': this.$store.state.adminKey
        }).then(() => {
          this.loading = false
          this.stage = 1
        }).catch(err => {
          console.log(err)
        })
      }
    },
    nextStage() {
      if(this.allowNextStageButton && !this.hasNegativeValue && !this.hasMoreThan2Dp && !this.invalidContribution && !this.isAdding){
        if(this.stage === 0.5) {
          this.setBaseAndAddRate()
          return
        }
        if(this.stage === 4) {
          this.addBill()
          return
        }
        if(this.stage === 5) {
          this.$emit('toggleShowAddBillScreen')
          this.resetAddBill()
          return
        }
        if(
          this.specialCharges.filter(specialCharge => specialCharge.isSpecialCharge).length === 0 &&
          this.stage === 2
        ) {
          this.stage += 1
        }
        this.stage += 1
      }
    },
    prevStage() {
      this.isAdding = false
      if(this.stage === 0) {
        this.$emit('toggleShowAddBillScreen')
      }
      if(this.stage > 0) {
        if(
          this.specialCharges.filter(specialCharge => specialCharge.isSpecialCharge).length === 0 &&
          this.stage === 4
        ) {
          this.stage -= 1
        }
        if(this.stage === 0.5) {
          this.stage += 1.5
        }
        this.stage -= 1
      }
    },
    updatePayerAmount(name, value){
      this.payers.forEach(payer => {
        if(payer.name === name) {
          payer.amount = value
        }
      })
    },
    updatePayerIsPayer(name, value){
      this.payers.forEach(payer => {
        if(payer.name === name) {
          payer.isPayer = value
        }
      })
    },
    addNewPayer(name, amount) {
      this.payers.push({
        isPayer: true,
        name: name.trim(),
        amount
      })
      masterFunction({
        'methodName': 'add-name',
        'eventId': this.event.eventId,
        'name': name.trim(),
        'adminKey': this.$store.state.adminKey
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    addNewPayee(name) {
      this.payees.push({
        isPayee: true,
        name: name.trim(),
        type: 'equal',
        amount: null
      })
      this.payeeInds.push({
        isPayee: false,
        name: name.trim(),
        amount: null
      })
      masterFunction({
        'methodName': 'add-name',
        'eventId': this.event.eventId,
        'name': name.trim(),
        'adminKey': this.$store.state.adminKey
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    updatePayeeAmount(name, value){
      this.payees.forEach(payee => {
        if(payee.name === name) {
          payee.amount = value
        }
      })
    },
    updatePayeeIndAmount(name, value){
      this.payeeInds.forEach(payeeInd => {
        if(payeeInd.name === name) {
          payeeInd.amount = value
        }
      })
    },
    setPayeeType(name, payeeType) {
      this.payees.forEach(payee => {
        if(payee.name === name) {
          payee.type = payeeType
        }
      })
    },
    updatePayeeIsPayee(name, value) {
      // if(
      //   this.payees.filter(payee => payee.isPayee).length === 1
      // ) {
      //   this.payees = this.payees.map(payee => {
      //     if (payee.name === name) {
      //       payee.type = 'equal'
      //       payee.amount = null
      //     }
      //     return payee
      //   })
      // }
      this.payees.forEach(payee => {
        if(payee.name === name) {
          payee.isPayee = value
          payee.type = 'equal'
          if(!value) {
            payee.type = 'excluded'
            payee.amount = null
          }
        }
      })
    },
    updatePayeeIndIsPayee(name, value) {
      if(
        this.payeeInds.filter(payeeInd => payeeInd.isPayee).length === 1
      ) {
        this.payeeInds = this.payeeInds.map(payeeInd => {
          payeeInd.amount = null
          return payeeInd
        })
      }
      this.payeeInds.forEach(payeeInd => {
        if(payeeInd.name === name) {
          payeeInd.isPayee = value
        }
      })
      console.log('hi')
    },
    addNewSpecialCharge(name) {
      this.specialCharges.push({
        name,
        split: 'equal',
        type: 'percentage',
        amount: null
      })
    },
    setSpecialChargeType(name, type){
      this.specialCharges.forEach(specialCharge => {
        if(specialCharge.name === name) {
          specialCharge.type = type
        }
      })
    },
    updateSpecialChargeAmount(name, value){
      this.specialCharges.forEach(specialCharge => {
        if(specialCharge.name === name) {
          specialCharge.amount = value
          if(value && value !== 0) {
            specialCharge.isSpecialCharge = true
          } else {
            specialCharge.isSpecialCharge = false
          }
        }
      })
    },
    deleteSpecialCharge(name) {
      this.specialCharges = JSON.parse(JSON.stringify(this.specialCharges)).filter(specialCharge => {
        return specialCharge.name !== name
      })
    },
    toggleIsSpecialCharge(name, value) {
      this.specialCharges.forEach(specialCharge => {
        if(specialCharge.name === name) {
          specialCharge.isSpecialCharge = value
          if(!specialCharge.isSpecialCharge) {
            specialCharge.amount = null
          }
        }
      })
    },
    setSpecialChargeSplitType(name, splitType){
      this.specialCharges.forEach(specialCharge => {
        if(specialCharge.name === name) {
          specialCharge.split = splitType
        }
      })
    }
  },
  computed: {
    exceedsFileSize () {
      if (this.photo && Math.round(this.photo.length*3/4) / 1024 / 1024 > 0.7) {
        return true
      }
      return false
    },
    itemTemplateNames() {
      return ['Meal', 'Dinner', 'Lunch', 'Drinks', 'Desserts', 'Snacks', 'Cab', 'Gift', 'Tickets', 'Accomodation']
    },
    buttonText() {
      return {
        0: this.itemIdToEdit ? 'Next: Edit Payers' : 'Next: Add Payers',
        0.5: 'Set Conversion Rate',
        1: this.itemIdToEdit ? 'Next: Edit Extra Charges' : 'Next: Add Extra Charges',
        2: this.specialCharges.filter(specialCharge => specialCharge.isSpecialCharge).length > 0 ? 'Next: Split Extra Charges' : 'Skip, no extra charges',
        3: this.itemIdToEdit ? 'Next: Edit Contributors' : 'Next: Add Contributors',
        4: this.itemIdToEdit ? 'Done!' : 'Done!',
        5: this.itemIdToEdit ? 'Close' : 'Close'
      }
    },
    allowNextStageButton() {
      return (
        this.billName.length > 0 && !this.exceedsFileSize && this.stage === 0 ||
        this.currencyValidityType === 'VALID' && this.stage === 0.5 ||
        Number(this.totalPaid) > 0 && this.stage === 1 ||
        (
          Number(this.totalSpecialCharges) === 0 && this.specialCharges.filter(specialCharge => specialCharge.isSpecialCharge).length === 0 ||
          Number(this.totalSpecialCharges) > 0 && this.specialCharges.filter(specialCharge => specialCharge.isSpecialCharge).length > 0 &&
          Number(this.totalSpecialCharges) > 0 && Number(this.totalPaid) >= Number(this.totalSpecialCharges)
        ) && this.stage === 2 ||
        this.stage === 3 ||
        this.stage === 4 ||
        this.stage === 5
      )
    },
    addBillCurrencySymbol() {
      return this.confirmedFromCurrency ? `${this.confirmedFromCurrency} ` : this.$store.state.baseCurrencySymbol
    },
    totalPaid() {
      return Math.round(this.payers.reduce((acc, payer) => {
        if(payer.isPayer){
          return acc + Number(payer.amount)
        } else {
          return acc
        }
      }, 0) * 1e2)/1e2
    },
    totalSpecialCharges() {
      const totalAbsoluteCharges = this.specialCharges.reduce((acc, specialCharge) => {
        if(specialCharge.isSpecialCharge && specialCharge.type === 'absolute') {
          return acc + Number(specialCharge.amount)
        }
        return acc
      }, 0)
      let nextTotalPercentageCharge = null
      let totalPercentageCharges = this.specialCharges.slice().reverse().reduce((acc, specialCharge) => {
        if (specialCharge.isSpecialCharge && specialCharge.type === 'percentage') {
          if(!nextTotalPercentageCharge) {
            nextTotalPercentageCharge = this.totalPaid
          }
          console.log('nextTotalPercentageCharge', nextTotalPercentageCharge)
          const percentageCharge = nextTotalPercentageCharge/(100+specialCharge.amount)*specialCharge.amount
          console.log('percentageCharge', percentageCharge)
          nextTotalPercentageCharge -= percentageCharge
          return acc + percentageCharge
        }
        return acc + 0
      }, 0)

      return Math.round((totalAbsoluteCharges + totalPercentageCharges) * 1e2)/1e2

    },
    specialChargesTemplates() {
      const templates = ['Tips', 'Delivery', 'Service Charge', 'GST']
      const specialChargesNames = this.specialCharges.map(specialCharge => specialCharge.name)
      return templates.filter(template => {
        return !specialChargesNames.includes(template)
      })
    },
    event() {
      return this.$store.state.event
    },
    hasNegativeValue() {
      return (
        this.stage === 1 &&
        this.payers.some(payer => {
          return (
            (payer.isPayer && payer.amount <= 0) ||
            (payer.isPayer && (!payer.amount || payer.amount === 0))
          )
        }) ||
        this.stage === 2 &&
        this.specialCharges
        .filter(specialCharge => specialCharge.isSpecialCharge)
        .some(specialCharge => { return specialCharge.amount !== null && specialCharge.amount <= 0 }) ||
        this.stage === 4 &&
        this.payees.some(payee => {
          return (
            payee.amount !== null && payee.amount < 0 ||
            ['absolute', 'percentage'].includes(payee.type) && (!payee.amount || payee.amount === 0)
          )
        })
      )
    },
    hasMoreThan2Dp() {
      return (
        this.stage === 1 &&
        this.payers.some(payer => {
          return payer.isPayer && payer.amount && payer.amount.countDecimals() > 2
        }) ||
        this.stage === 2 &&
        this.specialCharges.some(specialCharge => { return specialCharge.amount !== null && specialCharge.amount.countDecimals() > 2 }) ||
        this.stage === 4 &&
        this.payees.some(payee => {
          return payee.amount !== null && payee.amount.countDecimals() > 2
        })
      )
    },
    invalidContribution() {
      return (
        this.stage === 4 && (
          (
            this.payees.some(payee => { return payee.type === 'equal' }) &&
            Math.round((this.totalContribution + this.totalSpecialCharges)* 1e2)/1e2 > Math.round(this.totalPaid* 1e2)/1e2
          ) ||
          (
            this.payees.every(payee => { return payee.type !== 'equal' }) &&
            Math.round((this.totalContribution + this.totalSpecialCharges)* 1e2)/1e2 !== Math.round(this.totalPaid* 1e2)/1e2
          )
        )
      )
    },
    totalPayeeIndsContribution() {
      return this.payeeInds.reduce((acc, payeeInd) => {
        return acc + payeeInd.amount
      }, 0)
    },
    totalContribution() {
      let totalContribution = this.payees.reduce((acc, payee) => {
        if(payee.type === 'percentage') {
          return acc + (payee.amount/100)*(this.totalPaid-this.totalPayeeIndsContribution)
        } else if(payee.type === 'absolute') {
          return acc + payee.amount
        }
        return acc
      }, 0)
      totalContribution += this.payeeInds.reduce((acc, payeeInd) => {
        return acc + payeeInd.amount
      }, 0)
      return Math.round(totalContribution* 1e2)/1e2
    },
    contributionNeeded() {
      const contributionNeeded = this.totalPaid - this.totalContribution - this.totalSpecialCharges
      return Math.round((contributionNeeded) * 1e2)/1e2
    },
    hideNextStageButton() {
      return !(
        this.stage === 0 && !this.billName ||
        this.stage === 1 && this.totalPaid === 0 ||
        this.stage === 4 && this.payees.filter(payee => payee.isPayee).length === 0 && this.contributionNeeded !== 0
      )
    },
    currencyValidityType() {
      if(this.stage !== 0.5) {
        return false
      }
      if(
        !this.fromCurrency || !this.baseCurrency
      ) {
        return 'EMPTY'
      } else if(
        (this.fromCurrency && this.fromCurrency.length !== 3) ||
        (this.baseCurrency && this.baseCurrency.length !== 3)
      ) {
        return 'NEED_3_CHARS'
      } else if (
        (this.fromCurrency && this.fromCurrency.length === 3) &&
        (this.baseCurrency && this.baseCurrency.length === 3) &&
        !currencies.find(currency => currency.code === this.fromCurrency) ||
        !currencies.find(currency => currency.code === this.baseCurrency)
      ) {
        return 'WRONG_CODE'
      } else if (
        this.fromCurrency && this.fromCurrency.length === 3 &&
        this.baseCurrency && this.baseCurrency.length === 3 &&
        currencies.find(currency => currency.code === this.fromCurrency) &&
        currencies.find(currency => currency.code === this.baseCurrency)
      ) {
        return 'VALID'
      }
      return 'ERROR'
    }
  },
  watch: {
    showAddBillScreen(newShowAddBillScreen, oldShowAddBillScreen) {
      if(
        this.itemIdToEdit &&
        newShowAddBillScreen &&
        !oldShowAddBillScreen
      ) {
        this.resetAddBill()
      }
    },
    itemIdToEdit(newItemIdToEdit, oldItemIdToEdit) {
      if(
        (!oldItemIdToEdit && newItemIdToEdit) ||
        (
          oldItemIdToEdit && newItemIdToEdit &&
          oldItemIdToEdit !== newItemIdToEdit
        )
      ) {
        this.event.items.forEach(item => {
          if(item.itemId === newItemIdToEdit) {
            this.stage = 0
            this.billName = item.title
            this.payers = item.payers
            this.specialCharges = item.specialCharges
            this.payees = item.payees
            this.payeeInds = item.payeeInds
            this.confirmedFromCurrency = item.currency
            this.photo = item.photo
          }
        })
        if(this.confirmedFromCurrency) {
          this.confirmedBaseCurrency = this.event.baseCurrency
          this.confirmedRate = this.event.conversions.slice().reverse().find(
            conversion => conversion.fromCurrency === this.confirmedFromCurrency).rate
        }
      } else if (
        oldItemIdToEdit && !newItemIdToEdit
      ){
        this.resetAddBill()
      }
    },
    stage(newStage, oldStage) {
      if(
        oldStage !== 1 &&
        newStage === 1 &&
        this.payers.length === 0
      ) {
        this.event.names.forEach(name => {
          this.payers.push({
            isPayer: false,
            name,
            amount: null
          })
        })
      } else if (
        oldStage !== 0.5 &&
        newStage === 0.5
      ) {
        if(this.event.baseCurrency) {
          this.fromCurrency = this.confirmedFromCurrency
          this.baseCurrency = this.event.baseCurrency
          this.rate = this.confirmedRate
        }
      } else if (
        oldStage === 0.5 &&
        newStage !== 0.5
      ) {
        this.fromCurrency = null
        this.baseCurrency = null
        this.rate = null
      }else if (
        oldStage !== 2 &&
        newStage === 2 &&
        this.specialCharges.length === 0
      ) {
        this.specialCharges.push({
          isSpecialCharge: false,
          name: 'Service Charge',
          type: 'percentage',
          split: 'proportion',
          amount: null
        })
        this.specialCharges.push({
          isSpecialCharge: false,
          name: 'Taxes',
          type: 'percentage',
          split: 'proportion',
          amount: null
        })
        this.specialCharges.push({
          isSpecialCharge: false,
          name: 'Delivery',
          type: 'absolute',
          split: 'equal',
          amount: null
        })
      } else if (
        oldStage !== 3 &&
        newStage === 3
      ) {
        this.specialCharges = JSON.parse(JSON.stringify(this.specialCharges)).filter(specialCharge => {
          return specialCharge.isSpecialCharge
        })
      } else if (
        oldStage !== 4 &&
        newStage === 4 &&
        this.payees.length === 0
      ) {
        this.event.names.forEach(name => {
          this.payees.push({
            isPayee: true,
            name,
            type: 'equal',
            amount: null
          })
        })
        this.event.names.forEach(name => {
          this.payeeInds.push({
            isPayee: false,
            name,
            amount: null
          })
        })
      }
    },
    event(newEvent, oldEvent) {
      if(
        newEvent &&
        oldEvent &&
        newEvent.names.length > oldEvent.names.length
      ){
        const currentPayerNames = this.payers.map(payer => payer.name)
        newEvent.names.forEach(name => {
          if(!currentPayerNames.includes(name)) {
            this.payers.push({
              isPayer: false,
              name,
              amount: null
            })
          }
        })
        const currentPayeeNames = this.payees.map(payee => payee.name)
        newEvent.names.forEach(name => {
          if(!currentPayeeNames.includes(name)) {
            this.payees.push({
              isPayee: true,
              name,
              type: null,
              amount: null
            })
          }
        })
        const currentPayeeIndNames = this.payeeInds.map(payeeInd => payeeInd.name)
        newEvent.names.forEach(name => {
          if(!currentPayeeIndNames.includes(name)) {
            this.payeeInds.push({
              isPayee: false,
              name,
              amount: null
            })
          }
        })
      }
    },
    currencyValidityType(newCurrencyValidityType, oldCurrencyValidityType) {
      if(
        oldCurrencyValidityType !== 'VALID' &&
        newCurrencyValidityType === 'VALID' &&
        (
          !this.rate ||
          this.currencyChanged
        )
      ) {
        masterFunction({
          'methodName': 'get-currency-rate',
          'eventId': this.event.eventId,
          'fromCurr': this.fromCurrency,
          'toCurr': this.baseCurrency,
          'adminKey': this.$store.state.adminKey
        }).then(res => {
          this.rate = res.data.rate
        }).catch(err => {
          console.log(err)
        })
      }
    },
    fromCurrency(newFromCurrency) {
      if(
        [1,2].includes(newFromCurrency.length)
      ) {
        this.currencyChanged = true
      } else {
        this.currencyChanged = false
      }
    }
  }
}
</script>

<style>
#add-bill-action-button {
  margin-bottom: 75px;
}

.add-bill-card-modifier {
  background: white;
  min-height: 90vh;
  padding-bottom: 20px;
}

.back-button-modifier {
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}

.tick-animation {
  animation: pulse 0.6s ease-in-out 1;
}
@keyframes pulse{
  25%  {transform: scale(0.6);}
  75%  {transform: scale(1.2);}
}
</style>