<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        Data & Privacy
        </h3>
      </div>
      <div class="mb-4 has-text-grey">BestBillSplitter.com was created to use as little personal data as possible.</div>
      <PrivacyNotice />
      <div class="container mt-6" v-if="canEdit">
        <label class="label">Delete Event's Data Now</label>
        <div class="control">
          <input
          class="input"
          v-bind:class="{'is-loading': loading}"
          type="text"
          placeholder="type 'delete'"
          v-model="deleteConfirmation"
          :disabled="loading"
          @input="$event.target.composing = false"
          >
          <div
            @click="deleteEvent()"
            v-bind:class="{'is-loading': loading}"
            class="button is-danger is-fullwidth mt-3 mb-3"
            :disabled="deleteConfirmation !== 'delete' || loading"
            >
            Delete All Data
            </div>
        </div>
      </div>
      <div
      v-if="!loading"
      class="container mb-6 has-text-centered has-text-grey"
      >
        <div @click="togglePrivacyScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'
import PrivacyNotice from '@/components/PrivacyNotice'

export default {
  name: 'Privacy',
  components: {
    PrivacyNotice
  },
  data() {
    return {
      loading: false,
      deleteConfirmation: ''
    }
  },
  methods: {
    deleteEvent(){
      if(!this.loading && this.deleteConfirmation.toLowerCase() === 'delete') {
        this.loading = true
        const eventId = this.event.eventId
        masterFunction({
          'methodName': 'delete-event',
          'eventId': this.event.eventId,
          'adminKey': this.$store.state.adminKey
        }).then(() => {
          let previousEvents = localStorage.BestBillSplitterPreviousEventsString ? JSON.parse(localStorage.BestBillSplitterPreviousEventsString) : []
          previousEvents = previousEvents.filter(previousEvent => {
            return previousEvent.eventId !== eventId
          })
          localStorage.BestBillSplitterPreviousEventsString = JSON.stringify(previousEvents)
          this.loading = false
          this.$router.push({ name: 'Start' })
        }).catch(err => {
          console.log(err)
        })
      }
    },
    togglePrivacyScreen() {
      this.$emit('togglePrivacyScreen')
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    }
  }
}
</script>

<style>

</style>