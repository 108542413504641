<template>
  <div v-if="event && event.overallServiceCharge" class="container px-4">
    <nav class="level is-mobile mb-0">
      <div class="level-left">
        <div class="level-item">
          <div class="is-size-5 is-unselectable has-text-weight-semibold mb-0">
          Misc. Charges & Discounts
          </div>
        </div>
      </div>
      <!-- <div class="level-right">
        <div v-if="!canEdit" class="level-item">

        </div>
        <div v-if="canEdit" @click="toggleOverallSpecialChargeScreen()" class="level-item is-clickable">
          <button
          class="button is-small is-info is-rounded has-monserrat-font is-outlined"
          >
          <i class="fas fa-pencil-alt mr-2"></i>
          edit
          </button>
        </div>
      </div> -->
    </nav>
    <div v-if="event.overallServiceCharge.payers.length > 0" class="is-size-7 has-text-grey-light is-unselectable has-text-weight-semibold mb-0">
    split {{ event.overallServiceCharge.type === 'equal' ? 'equally' : 'by individual expenses' }}
    </div>
    <nav @click="toggleOverallSpecialChargeScreen()" v-for="(payer, index) in payers" :key="index" class="level py-1 px-2 is-clickable is-unselectable is-mobile item-row-modifier mb-1">
      <div class="level-left">
        <div class="level-item is-size-7">
          Paid by {{ shortenName(payer.name) }}
        </div>
      </div>

      <div class="level-right">
        <p class="level-item"><strong>{{$store.state.baseCurrencySymbol}}{{ payer.amount }}</strong></p>
      </div>
    </nav>
    <div v-if="event.overallServiceCharge.reduction" class="is-size-7 has-text-grey-light is-unselectable has-text-weight-semibold mb-0">
    discounts are spread proportionally to each items' price
    </div>
    <nav @click="toggleOverallSpecialChargeScreen()" v-if="event.overallServiceCharge.reduction" class="level py-1 px-2 is-clickable is-unselectable is-mobile item-row-modifier mb-1">
      <div class="level-left">
        <div class="level-item is-size-7">
          Discounts
        </div>
      </div>

      <div class="level-right">
        <p class="level-item"><strong>-{{$store.state.baseCurrencySymbol}}{{ event.overallServiceCharge.reduction }}</strong></p>
      </div>
    </nav>
    <div v-if="excludedItemsText" class="is-size-7 has-text-grey-light is-unselectable has-text-weight-semibold mt-2 mb-0">
    Excluded: {{ excludedItemsText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverallServiceCharges',
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    },
    payers() {
        return this.event.overallServiceCharge.payers.filter(payer => {
            return payer.amount && payer.amount > 0
        })
    },
    excludedItemsText() {
      if(
        this.event.overallServiceCharge &&
        this.event.overallServiceCharge.excludedItemIds
      ) {
        const itemTitles = this.event.items.filter(item => {
          return this.event.overallServiceCharge.excludedItemIds.includes(item.itemId)
        }).map(item => item.title)
        return itemTitles.join(', ')
      } else {
        return null
      }
    }
  },
  methods: {
    toggleOverallSpecialChargeScreen() {
      this.$emit('toggleOverallSpecialChargeScreen')
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    }
  }
}
</script>

<style>
.payment-row-modifier {
  border: 1.5px solid;
  border-radius: 5px;
}
</style>