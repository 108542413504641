<template>
  <div class="level is-mobile" v-bind:class="{'mt-2': !payeeInd}">
    <div class="level-left">
      <div @click="toggleIsPayee()" v-if="payeeInd" class="level-item is-clickable mr-1">
        <i v-if="payeeInd.isPayee" class="far fa-check-square"></i>
        <i v-if="!payeeInd.isPayee" class="far fa-square"></i>
      </div>
      <div
      @click="toggleIsPayee()"
      v-if="payeeInd && payeeInd.name"
      class="payee-name-modifier is-clickable is-unselectable"
      >
        {{ payeeDisplayName }}
      </div>
      <div v-if="!payeeInd" class="level-item mr-1">
        <input
        class="input name-input-modifier has-background-info-light"
        v-bind:class="{'is-danger': newName && newName.length > 0 && isDuplicateNewName}"
        type="text"
        placeholder="Add new name"
        v-model="newName"
        @input="$event.target.composing = false"
        >
      </div>
      <div v-if="!payeeInd" class="level-item">
        <div
        v-if="newName"
        @click="addNewPayee()"
        class="button is-info"
        :disabled="!(newName && newName.length > 0) || isDuplicateNewName"
        v-bind:class="{'glow': newName && newName.length > 0}"
        >
        <i class="fas fa-plus"></i>
        </div>
      </div>
    </div>
    <div v-if="payeeInd && payeeInd.isPayee" class="level-right">
      <div class="tags has-addons level-item">
        <span class="tag is-clickable is-large px-2 mb-0">
          <i class="fas fa-dollar-sign"></i>
        </span>
      </div>
      <div class="level-item mr-0">
        <input
        class="input payee-ind-amount-modifier"
        v-bind:class="{'glow': payeeInd.isPayee && (!localValue || localValue < 0)}"
        type="number"
        placeholder="0"
        v-model="localValue"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayeeIndividual',
  props: ['payeeInds', 'payeeInd'],
  data() {
    return {
      amountInput: null,
      newName: null
    }
  },
  computed: {
    isDuplicateNewName(){
      return this.$store.state.event.names.includes(this.newName)
    },
    localValue: {
      get () {
        return this.payeeInd && this.payeeInd.amount ? this.payeeInd.amount : null
      },
      set (value) {
        if(value !== this.localValue){
          this.$emit('updatePayeeIndAmount', Number(value))
        }
      }
    },
    payeeDisplayName() {
      return this.payeeInd.name.length < 12 ? this.payeeInd.name : this.payeeInd.name.substring(0, 12) + '...'
    }
  },
  watch: {
    localValue(newLocalValue) {
      if(newLocalValue > 0 && !this.payeeInd.isPayee) {
        this.$emit('updatePayeeIndIsPayee', true)
      }
    },
    newName(newName) {
      if(newName) {
        this.$emit('updateIsAdding', true)
      } else {
        this.$emit('updateIsAdding', false)
      }
    }
  },
  methods: {
    toggleIsPayee() {
      if(this.payeeInd.isPayee){
        this.localValue = null
      }
      this.$emit('updatePayeeIndIsPayee', !this.payeeInd.isPayee)
    },
    addNewPayee() {
      if(
        this.newName &&
        this.newName.length > 0 &&
        !this.isDuplicateNewName
      ){
        this.$emit('addNewPayee', { newName: this.newName })
        this.newName = null
      }
    }
  }
}
</script>

<style>
.payee-name-modifier {
 width: 40px;
}

.name-input-modifier {
  min-width: 160px !important;
}

.payee-ind-amount-modifier {
  max-width: 120px;
}
</style>