<template>
  <div v-if="event" class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        Download CSV
        </h3>
      </div>
      <button 
        @click="downloadCsv()"
        class="button has-baskerville-font has-text-weight-semibold is-info is-fullwidth mb-3"
        >
        Click to download
      </button >
      <div
      class="container mb-6 has-text-centered has-text-grey"
      >
        <div @click="toggleDownloadCsvScreen()" class="is-clickable is-unselectable back-button-modifier">
          close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadCsv',
  data() {
    return {
      copied: false
    }
  },
  methods: {
    toggleDownloadCsvScreen() {
      this.copied = false
      this.$emit('toggleDownloadCsvScreen')
    },
    downloadCsv() {
      const event = this.event
      const filename = `${this.event.name}_bestbillsplittercom.csv`

      const rows = []
      rows.push(['Items'])
      rows.push([])
      let itemHeaderRow = ['Name']
      event.names.forEach(name => {
        itemHeaderRow.push(`${name} (paid)`)
      })
      itemHeaderRow.push('Amount')
      itemHeaderRow.push('Currency')
      itemHeaderRow.push('Misc. Charges')
      event.names.forEach(name => {
        itemHeaderRow.push(`${name} (owe)`)
      })
      rows.push(itemHeaderRow)
      event.itemOutputs.forEach((itemOutput, index) => {
        const itemRow = []
        itemRow.push(event.items[index].title)
        event.names.forEach(name => {
          let found = false
          itemOutput.itemPayments.forEach(itemPayment => {
            if(name === itemPayment.name) {
              itemRow.push(itemPayment.amount)
              found = true
            }
          })
          if(!found) {
            itemRow.push(0)
          }
        })
        itemRow.push(event.items[index].totalPaid)
        itemRow.push(
          event.items[index].currency ? event.items[index].currency : '$')
        const specialChargesStrings = []
        itemOutput.itemServiceCharges.forEach((itemServiceCharge, index2) => {
          specialChargesStrings.push(
            `${itemServiceCharge.name} (${event.items[index].currency ? event.items[index].currency : '$'}${itemServiceCharge.amount}, split ${event.items[index].specialCharges[index2].split === 'proportion' ? 'proportionally' : 'equally'})`)
        })
        itemRow.push(specialChargesStrings.join(', '))
        event.names.forEach(name => {
          let found = false
          itemOutput.itemDebts.forEach(itemDebt => {
            if(name === itemDebt.name) {
              itemRow.push(itemDebt.amount)
              found = true
            }
          })
          if(!found) {
            itemRow.push(0)
          }
        })
        rows.push(itemRow)
      })
      rows.push([])
      rows.push([])
      if(event.overallServiceCharge) {
        rows.push(['Overall Misc Charges & Discounts'])
        rows.push([])
        if(event.overallServiceCharge.payers.length > 0) {
          rows.push(['Paid By', 'Amount', 'Currency', 'Split Type'])
          event.overallServiceCharge.payers.forEach(payer => {
            if(payer.isPayer) {
              rows.push([
                payer.name, payer.amount, event.currency ? event.currency : '$', event.overallServiceCharge.type === 'equal' ? 'equally' : 'proportionally'
              ])
            }
          })
          rows.push([])
        }
        if(event.overallServiceCharge.reduction) {
          rows.push(['Discounts:', event.overallServiceCharge.reduction])
        }
        rows.push([])
      }
      rows.push(['Settlements'])
      rows.push([])
      rows.push(['From', 'To', 'Amount', 'Currency'])
      event.payments.forEach(payment => {
        rows.push([
          payment.from, payment.to, payment.amount, event.currency ? event.currency : '$'
        ])
      })
      
      var processRow = function (row) {
        var finalVal = ''
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString()
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString()
          }
          var result = innerValue.replace(/"/g, '""')
          if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"'
          if (j > 0)
            finalVal += ','
          finalVal += result
        }
        return finalVal + '\n'
      }

      var csvFile = ''
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
      }

      var blob = new Blob([csvFile], { type: 'text/csvcharset=utf-8' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    textToCopy() {
      let textToCopy = ''
      const event = this.event
      textToCopy += `**${event.name}**\n\n-Items-\n`
      event.items.forEach((item, index) => {
        textToCopy += `${index + 1}) ${item.title} (${item.currency ? `${item.currency} ` : this.$store.state.baseCurrencySymbol}${item.totalPaid})\n`
        textToCopy += `Paid by: ${this.paidByTexts[index]}`
        textToCopy += `\n`
        textToCopy += `Split between: ${this.splitBetweenTexts[index]}`
        textToCopy += `\n`
        if(this.extraChargesTexts[index] && this.extraChargesTexts[index].length > 0){
          textToCopy += `Extra charges: ${this.extraChargesTexts[index]}`
          textToCopy += `\n`
        }
      })
      if(event.overallServiceCharge) {
        textToCopy += `\n`
        textToCopy += `-Overall Misc. Charge-\n`
        textToCopy += `*${event.overallServiceCharge.type === 'equal' ? 'split equally' : 'weighted by expenses'}*\n`
        event.overallServiceCharge.payers.forEach((payer) => {
          if(payer.isPayer){
            textToCopy += `${payer.name} (${this.$store.state.baseCurrencySymbol}${payer.amount})`
            textToCopy += `\n`
          }
        })
      }
      textToCopy += `\n`
      textToCopy += `-Settlements-\n`
      event.payments.forEach((payment) => {
        textToCopy += `${payment.from} to ${payment.to} | ${this.$store.state.baseCurrencySymbol}${payment.amount}`
        textToCopy += `\n`
      })
      textToCopy += `\n`
      if(event.comments.length > 0) {
        textToCopy += `-Comments-\n`
        event.comments.forEach((comment) => {
          textToCopy += `${comment.from}: ${comment.comment}`
          textToCopy += `\n`
        })
      }
      textToCopy += `\n`
      textToCopy += `View in browser: https://bestbillsplitter.com/view/${event.nameSlug}/${event.eventId}`
      return textToCopy
    },
    paidByTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemPayments.map(itemPayment => {
          return `${itemPayment.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemPayment.amount})`
        }).join(', ')
      })
    },
    splitBetweenTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemDebts.map(itemDebt => {
          return `${itemDebt.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemDebt.amount})`
        }).join(', ')
      })
    },
    extraChargesTexts() {
      return this.event.itemOutputs.map(itemOutput => {
        return itemOutput.itemServiceCharges.map(itemServiceCharge => {
          return `${itemServiceCharge.name} (${this.event.baseCurrency ? `${this.event.baseCurrency} ` : '$'}${itemServiceCharge.amount.toFixed(2)})`
        }).join(', ')
      })
    }
  }
}
</script>

<style>

</style>