<template>
  <div v-if="event && event.payments" class="container px-4">
    <div class="is-size-5 is-unselectable has-text-weight-semibold">
    Comments
    </div>
    <p class="help has-text-grey-light mt-0 mb-2 is-size-7 is-unselectable">How to pay you, which account, etc</p>
    <div v-if="canEdit" class="columns py-0 my-0 is-mobile is-vcentered is-gapless">
      <div class="column">
        <input
        class="input is-small"
        type="text"
        placeholder="Add a comment here..."
        v-model="comment"
        @input="$event.target.composing = false"
        >
      </div>
      <div class="column ml-1 is-narrow">
        <div @click="addComment()"
        class="button is-primary-colors has-text-weight-semibold is-fullwidth is-small"
        v-bind:class="{'has-background-info-light': comment.length > 0}"
        >
          <i v-if="!loading && comment.length === 0" class="far fa-paper-plane pointer"></i>
          <i v-if="!loading && comment.length > 0" class="fas fa-paper-plane pointer has-text-info"></i>
          <i v-if="loading" class="spinning fas fa-spinner"></i>
        </div>
      </div>
    </div>
    <div v-if="!canEdit && event.comments.length === 0" class="is-size-7 has-text-grey-light mt-5 has-text-centered">
      No comments yet.
    </div>
    <div class="mt-2">
      <nav
      v-for="(comment, index) in event.comments.slice().reverse()"
      :key="index"
      class="columns is-vcentered is-gapless px-1 is-mobile py-1 is-mobile comment-row-modifier mb-1">
        <div class="column is-narrow">
          <div class="is-size-7 is-unselectable has-text-weight-semibold">
          {{ shortenName(comment.from) }}:
          </div>
        </div>
        <div class="column is-narrow mr-1">
          <div @click="copy(comment.comment)" class="level-item is-clickable is-size-7 ml-2">
            <i v-if="copied !== comment.comment" class="far fa-copy is-unselectable"></i>
            <div v-if="copied === comment.comment" class="has-text-grey is-unselectable">copied</div>
          </div>
        </div>
        <div class="column is-8">
          <div class="level-item is-size-7 break-word is-pulled-left">
          {{ comment.comment }}
          </div>
        </div>
        <div v-if="canEdit" class="column mr-1">
          <div v-if="!loading" @click="removeComment(comment.commentId)" class="level-item is-clickable is-pulled-right">
            <i class="fas fa-times has-text-info"></i>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'

export default {
  name: 'Comments',
  data() {
      return {
        comment: '',
        loading: false,
        copied: false
      }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    canEdit() {
      return this.$store.state.canEdit
    }
  },
  methods: {
    addComment() {
      if(!this.loading && this.comment.trim().length > 0) {
        this.loading = true
        masterFunction({
          'methodName': 'add-comment',
          'eventId': this.event.eventId,
          'adminKey': this.$store.state.adminKey,
          'from': this.$store.state.name,
          'comment': this.comment
        }).then(() => {
          this.comment = ''
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      }
    },
    removeComment(commentId) {
      this.loading = true
      masterFunction({
        'methodName': 'remove-comment',
        'eventId': this.event.eventId,
        commentId: commentId,
        'adminKey': this.$store.state.adminKey
      }).then(() => {
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    setCopiedToFalse(){
      this.copied = false
    },
    async copy(comment) {
      this.copied = comment
      await navigator.clipboard.writeText(comment)
      setTimeout(this.setCopiedToFalse, 1000)
    },
    shortenName(name) {
      return name.length < 10 ? name : name.substring(0, 9) + '...'
    }
  }
}
</script>

<style>
.comment-row-modifier {
  border: 1.5px solid #cccccc;
  border-radius: 5px;
}

.comment-text-modifier {
  /* width: 50%; */
}
</style>