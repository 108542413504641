<template>
  <div class="card add-bill-modifier has-rounded-top-corners">
    <div class="card-content add-bill-card-modifier">
      <div class="container has-text-weight-semibold has-text-centered">
        <h3 class="title is-4 has-baskerville-font has-text-brand-color mb-5">
        Confirm Delete
        </h3>
      </div>
      <div class="container">
        <label class="label">Confirm?</label>
        <div class="control">
          <div
            @click="deleteBill()"
            v-bind:class="{'is-loading': loading}"
            class="button is-danger is-fullwidth mt-3 mb-3"
            :disabled="loading"
            >
            Delete "{{ item.title }}"
            </div>
        </div>
        <div
        v-if="!loading"
        class="container mb-6 has-text-centered has-text-grey"
        >
          <div @click="toggleDeleteBillScreen()" class="is-clickable is-unselectable back-button-modifier">
            back
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'

export default {
  name: 'DeleteBill',
  props: ['item'],
  data() {
    return  {
      loading: false
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    }
  },
  methods: {
    deleteBill() {
      if(!this.loading){
        this.loading = true
        masterFunction({
          'methodName': 'remove-item',
          'eventId': this.event.eventId,
          'itemId': this.item.itemId,
          'adminKey': this.$store.state.adminKey
        }).then(() => {
          this.loading = false
          this.toggleDeleteBillScreen()
        }).catch(err => {
          console.log(err)
        }) 
      }
    },
    toggleDeleteBillScreen() {
      if(!this.loading){
        this.$emit('toggleDeleteBillScreen', false)
      }
    }
  },
  mounted() {
    console.log(this.item.title)
  }
}
</script>

<style>

</style>